import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Popover,
  InputLabel,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConstructionIcon from '@mui/icons-material/Construction';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { FormContext } from '../context/FormContext';
import "@fontsource/roboto";

const ConstructionPlantEquipment = ({ color, q7 }) => {
  const { constructionPlantEquipmentData, handleConstructionPlantEquipmentChange } = useContext(FormContext);
  const [expanded, setExpanded] = useState(false);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  useEffect(() => {
    // console.log('Received q7 data:', q7);
    
    if (q7 && Array.isArray(q7)) {
      try {
        // Map the incoming data to match the component's expected format
        const formattedData = q7.map((item, index) => ({
          id: index,
          number: (index + 1).toString(),
          plant_and_equipment: item.plantName || '',
          safety_consideration: item.maintenanceRequirements || [],
          standards_and_regulations: item.inspectionRequirements || []
        }));
  
        // console.log('Formatted data:', formattedData);
        handleConstructionPlantEquipmentChange(formattedData);
      } catch (error) {
        console.error('Error processing Construction Plant Equipment data:', error);
        handleConstructionPlantEquipmentChange([{
          id: 0,
          number: "1",
          plant_and_equipment: "",
          safety_consideration: [],
          standards_and_regulations: []
        }]);
      }
    } else {
      // Ensure at least one empty field
      handleConstructionPlantEquipmentChange([{
        id: 0,
        number: "1",
        plant_and_equipment: "",
        safety_consideration: [],
        standards_and_regulations: []
      }]);
    }
  }, [q7]);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleAddEquipment = () => {
    const newEquipment = {
        id: constructionPlantEquipmentData.length,
        number: (constructionPlantEquipmentData.length + 1).toString(),
        plant_and_equipment: "",
        safety_consideration: [],
        standards_and_regulations: []
    };
    handleConstructionPlantEquipmentChange([...constructionPlantEquipmentData, newEquipment]);
};

  const handleRemoveEquipment = (idToRemove) => {
    if (constructionPlantEquipmentData.length > 1) {
      const updatedEquipment = constructionPlantEquipmentData
        .filter(equipment => equipment.id !== idToRemove)
        .map((equipment, index) => ({ ...equipment, id: index }));
      handleConstructionPlantEquipmentChange(updatedEquipment);
    }
  };

  return (
    <Card sx={{
      boxShadow: 3,
      border: `4px solid ${color}`,
      backgroundColor: "white",
      opacity: 0.9,
    }}>
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
              sx={{ padding: 0, color: "black" }}
            >
              <ConstructionIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
              }}
            >
              Construction Plant and Equipment
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent sx={{
            padding: 0,
            marginBottom: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}>
            <Grid container spacing={2}>
              {constructionPlantEquipmentData.map((equipment) => (
                <Grid item xs={4} key={equipment.id}>
                  <Box sx={{
                    border: `2px solid ${color}`,
                    padding: 2,
                    borderRadius: "3px",
                    position: "relative",
                    backgroundColor: "#E9EAEC",
                  }}>
                    {showDeleteIcons && constructionPlantEquipmentData.length > 1 && (
                      <IconButton
                        onClick={() => handleRemoveEquipment(equipment.id)}
                        sx={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          padding: "0",
                          color: "red",
                        }}
                      >
                        <CancelIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    )}
                    
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <InputLabel
                          htmlFor={`activity-${equipment.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Plant and Equipment Activity
                        </InputLabel>
                        <TextareaAutosize
                          id={`activity-${equipment.id}`}
                          value={equipment.plant_and_equipment || ''}
                          onChange={(e) => {
                            const updatedEquipment = constructionPlantEquipmentData.map(item =>
                              item.id === equipment.id ? { ...item, plant_and_equipment: e.target.value } : item
                            );
                            handleConstructionPlantEquipmentChange(updatedEquipment);
                          }}
                          minRows={3}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`safety-${equipment.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Safety Maintenances
                        </InputLabel>
                        <TextareaAutosize
                          id={`safety-${equipment.id}`}
                          value={Array.isArray(equipment.safety_consideration) 
                              ? equipment.safety_consideration.join('\n') 
                              : ''}
                          onChange={(e) => {
                            const updatedEquipment = constructionPlantEquipmentData.map(item =>
                              item.id === equipment.id 
                                  ? { ...item, safety_consideration: e.target.value.split('\n').filter(line => line.trim()) } 
                                  : item
                            );
                            handleConstructionPlantEquipmentChange(updatedEquipment);
                          }}
                          minRows={4}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`standards-${equipment.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Standards and Regulations
                        </InputLabel>
                        <TextareaAutosize
                          id={`standards-${equipment.id}`}
                          value={Array.isArray(equipment.standards_and_regulations) 
                              ? equipment.standards_and_regulations.join('\n') 
                              : ''}
                          onChange={(e) => {
                            const updatedEquipment = constructionPlantEquipmentData.map(item =>
                              item.id === equipment.id 
                                  ? { ...item, standards_and_regulations: e.target.value.split('\n').filter(line => line.trim()) } 
                                  : item
                            );
                            handleConstructionPlantEquipmentChange(updatedEquipment);
                          }}
                          minRows={3}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "relative",
        }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: color,
                  "&.Mui-checked": {
                    color: color,
                  },
                  "& .MuiCheckbox-root": {
                    color: color,
                    "&.Mui-checked": {
                      color: color,
                    },
                  },
                }}
              />
            }
            label="Delete Equipment"
          />

          <Button
            variant="contained"
            component="span"
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            onClick={handleAddEquipment}
            startIcon={<AddIcon />}
          >
            Add Equipment
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default ConstructionPlantEquipment;