import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  TextField,
  Card,
  Box,
  InputLabel,
  TextareaAutosize,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { HardHat } from "lucide-react";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/roboto";
import { FormContext } from "../../context/FormContext";

const PPECard = ({ color }) => {
  const { ppeCardData, handlePPECardChange, documentColor } = useContext(FormContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (ppeCardData.length === 0) {
      // Add a default PPE item if the list is empty
      handlePPECardChange([{
        id: 0,
        number: "",
        activity: "",
        ppeDescription: "",
        australianStandards: "",
      }]);
    }
  }, [ppeCardData, handlePPECardChange]);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddPPE = () => {
    const newPPE = {
      id: ppeCardData.length,
      number: "",
      activity: "",
      ppeDescription: "",
      australianStandards: "",
    };
    handlePPECardChange([...ppeCardData, newPPE]);
  };

  const handlePPEChange = (id, e) => {
    const { name, value } = e.target;
    const updatedList = ppeCardData.map((ppe) =>
      ppe.id === id ? { ...ppe, [name]: value } : ppe
    );
    handlePPECardChange(updatedList);
  };

  const handleDeletePPE = (id) => {
    if (ppeCardData.length > 1) {
      const updatedList = ppeCardData.filter((ppe) => ppe.id !== id);
      handlePPECardChange(updatedList);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${documentColor}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: documentColor,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
              sx={{ padding: 0, color: "black" }}
            >
              <HardHat />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
            >
              Personal Protective Equipment
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              {ppeCardData.map((ppe) => (
                <Grid item xs={12} md={4} key={ppe.id}>
                  <Box
                    sx={{
                      border: `2px solid ${documentColor}`,
                      padding: 2,
                      borderRadius: "3px",
                      height: '100%',
                      position: "relative",
                    }}
                  >
                    {showDeleteIcons && ppeCardData.length > 1 && (
                      <IconButton
                        onClick={() => handleDeletePPE(ppe.id)}
                        sx={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          padding: "0",
                          color: "red",
                        }}
                      >
                        <CancelIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    )}
                    <Grid container direction="column" spacing={2}>
                      {/* <Grid item>
                        <InputLabel
                          htmlFor={`number-${ppe.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Number
                        </InputLabel>
                        <TextField
                          fullWidth
                          id={`number-${ppe.id}`}
                          name="number"
                          value={ppe.number}
                          onChange={(e) => handlePPEChange(ppe.id, e)}
                          variant="outlined"
                          size="small"
                          sx={{
                            bgcolor: "white",
                            borderRadius: "5px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid> */}
                      <Grid item>
                        <InputLabel
                          htmlFor={`activity-${ppe.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Item
                        </InputLabel>
                        <TextField
                          fullWidth
                          id={`activity-${ppe.id}`}
                          name="activity"
                          value={ppe.activity}
                          onChange={(e) => handlePPEChange(ppe.id, e)}
                          variant="outlined"
                          size="small"
                          sx={{
                            bgcolor: "white",
                            borderRadius: "5px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`ppeDescription-${ppe.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Description
                        </InputLabel>
                        <TextareaAutosize
                          id={`ppeDescription-${ppe.id}`}
                          name="ppeDescription"
                          value={ppe.ppeDescription}
                          onChange={(e) => handlePPEChange(ppe.id, e)}
                          minRows={4}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`australianStandards-${ppe.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          AS/NZS Standards
                        </InputLabel>
                        <TextareaAutosize
                          id={`australianStandards-${ppe.id}`}
                          name="australianStandards"
                          value={ppe.australianStandards}
                          onChange={(e) => handlePPEChange(ppe.id, e)}
                          minRows={4}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: documentColor,
                  "&.Mui-checked": {
                    color: documentColor,
                  },
                }}
              />
            }
            label="Delete PPE"
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleAddPPE}
          >
            Add PPE
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default PPECard;