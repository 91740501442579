import React, { useState, useContext, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  TextField,
  Card,
  Box,
  InputLabel,
  TextareaAutosize,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { HardHat } from "lucide-react";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/roboto";
import { FormContext } from '../context/FormContext';

const PPECard = ({ color, q4 }) => {
  const { ppeCardData, handlePPECardChange } = useContext(FormContext);

  // console.log("ppeCardData", ppeCardData)

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // console.log("q4 data", q4)

  useEffect(() => {
    // Process q4 data when available
    if (q4 && Array.isArray(q4) && q4.length > 0) {
      try {
        // Map the data to match the expected format
        const mappedData = q4.map((item, index) => ({
          id: index, // Use index as id
          activity: item.activity || "",
          ppeDescription: item.ppeDescription || "",
          australianStandards: item.australianStandards || ""
        }));
        
        // console.log("Mapped PPE data:", mappedData);
        handlePPECardChange(mappedData);
      } catch (error) {
        console.error("Error processing PPE data:", error);
      }
    } else if (!ppeCardData || ppeCardData.length === 0) {
      // Initialize with one empty card if no data exists
      const initialPPE = {
        id: 0,
        activity: "",
        ppeDescription: "",
        australianStandards: "",
      };
      handlePPECardChange([initialPPE]);
    }
  }, [q4]); // Only depend on q4 changes

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddPPE = () => {
    const newPPE = {
      id: ppeCardData.length,
      activity: "",
      ppeDescription: "",
      australianStandards: "",
    };
    handlePPECardChange([...ppeCardData, newPPE]);
  };

  const handlePPEChange = (id, field, value) => {
    const updatedList = ppeCardData.map((ppe) =>
      ppe.id === id ? { ...ppe, [field]: value } : ppe
    );
    handlePPECardChange(updatedList);
  };

  const handleDeletePPE = (id) => {
    if (ppeCardData.length > 1) {
      const updatedList = ppeCardData.filter((ppe) => ppe.id !== id);
      handlePPECardChange(updatedList);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${color}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
              sx={{ padding: 0, color: "black" }}
            >
              <HardHat />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
            >
              Personal Protective Equipment
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              {ppeCardData.map((ppe) => (
                <Grid item xs={12} md={4} key={ppe.id}>
                  <Box
                    sx={{
                      border: `2px solid ${color}`,
                      padding: 2,
                      borderRadius: "3px",
                      height: '100%',
                      position: "relative",
                    }}
                  >
                    {showDeleteIcons && ppeCardData.length > 1 && (
                      <IconButton
                        onClick={() => handleDeletePPE(ppe.id)}
                        sx={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          padding: "0",
                          color: "red",
                        }}
                      >
                        <CancelIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    )}
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <InputLabel
                          htmlFor={`activity-${ppe.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Item
                        </InputLabel>
                        <TextField
                          fullWidth
                          id={`activity-${ppe.id}`}
                          name="activity"
                          value={ppe.activity}
                          onChange={(e) => handlePPEChange(ppe.id, "activity", e.target.value)}
                          variant="outlined"
                          size="small"
                          sx={{
                            bgcolor: "white",
                            borderRadius: "5px",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`ppeDescription-${ppe.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          Description
                        </InputLabel>
                        <TextareaAutosize
                          id={`ppeDescription-${ppe.id}`}
                          name="ppeDescription"
                          value={ppe.ppeDescription}
                          onChange={(e) => handlePPEChange(ppe.id, "ppeDescription", e.target.value)}
                          minRows={4}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          htmlFor={`australianStandards-${ppe.id}`}
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#424242",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        >
                          AS/NZS Standards
                        </InputLabel>
                        <TextareaAutosize
                          id={`australianStandards-${ppe.id}`}
                          name="australianStandards"
                          value={ppe.australianStandards}
                          onChange={(e) => handlePPEChange(ppe.id, "australianStandards", e.target.value)}
                          minRows={4}
                          style={{
                            minWidth: "98.7%",
                            maxWidth: "98.7%",
                            padding: "8px",
                            marginTop: "8px",
                            borderRadius: "5px",
                            borderColor: "grey",
                            fontFamily: "Roboto, sans-serif",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: color,
                  "&.Mui-checked": {
                    color: color,
                  },
                }}
              />
            }
            label="Delete PPE"
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleAddPPE}
          >
            Add PPE
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default PPECard;