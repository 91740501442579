import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import backgroundVideo from "../assets/icrommBG.mp4";
import Logo from "../assets/icrommLogoWhiteLarge.png";

export default function LoginForm() {
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const API_URL = 'https://www.icromm.com/api';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      const formData = new URLSearchParams();
      formData.append("username", email);
      formData.append("password", password);

      const response = await axios.post(
        `${API_URL}/login`,
        formData,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );

      if (response.data.access_token) {
        if (response.data.is_superadmin) {
          login(response.data.access_token);
          navigate("/admin-dashboard");
        } else if (response.data.payment_status === "done") {
          login(response.data.access_token);
          navigate("/user-dashboard");
        } else {
          setMessage("Please complete your payment.");
          navigate("/payment");
        }
      }
    } catch (error) {
      if (error.response && error.response.data.detail) {
        setMessage(error.response.data.detail);
      } else {
        setMessage("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      {/* <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30"></div> */}

      <div className="relative flex flex-col items-center">
        <img src={Logo} alt="ICROMM Logo" className="w-[150px] mb-6 z-10  pl-2 py-1" />

        <div className="relative w-[400px] bg-white rounded-lg shadow-lg p-6 z-10">
          <div className="space-y-1 text-center">
            <h2 className="text-2xl font-semibold">Welcome back</h2>
            <p className="text-sm text-gray-500">
              Glad to see you again 👋
              <br />
              Login to your account below
            </p>
          </div>

          <div className="mt-4">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder="Enter email..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-lg border border-gray-300 p-2"
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Enter password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full rounded-lg border border-gray-300 p-2"
                />
              </div>

              {message && (
                <p
                  className={`text-center mt-4 ${
                    message.includes("successful") ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {message}
                </p>
              )}

              <button
                type="submit"
                className="w-full rounded-lg bg-blue-900 text-white p-2 hover:bg-blue-800"
              >
                Login
              </button>
            </form>

            <div className="mt-4 text-center text-sm">
              <div className="space-y-2">
                <div>
                  Don't have an account?{" "}
                  <a href="/signup" className="text-blue-900 hover:underline">
                    Sign up
                  </a>
                </div>
                <div>
                  <a href="/forgot-password" className="text-blue-900 hover:underline">
                    Forgot Password?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Company Name Text */}
        <div className="mt-4 p-2 rounded-lg text-center z-10">
          <p className="text-white text-sm">ICROMM Pty Ltd.</p>
        </div>
      </div>
    </div>
  );
}
