import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { FaFile, FaCalendar, FaCoins, FaArrowLeft, FaProjectDiagram, FaFileAlt } from 'react-icons/fa';

const cookies = new Cookies();

const DocumentCard = ({ document }) => (
  <div className="bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300">
    <div className="flex items-center mb-4">
      <FaFile className="text-blue-500 mr-3 text-xl" />
      <h3 className="text-xl font-bold text-gray-800">{document.project_details?.swmsTitle || 'Untitled Document'}</h3>
    </div>
    <div className="text-gray-600 mb-2 flex items-center">
      <FaProjectDiagram className="mr-2 text-green-500" />
      <span>Project: {document.project_details?.projectName || 'Unnamed Project'}</span>
    </div>
    <div className="text-gray-600 mb-2 flex items-center">
      <FaFileAlt className="mr-2 text-purple-500" />
      <span>SWMS No: {document.project_details?.swmsNo || 'N/A'}</span>
    </div>
    <div className="text-gray-600 mb-2 flex items-center">
      <FaCalendar className="mr-2" />
      <span>Created: {new Date(document.created_at).toLocaleDateString()}</span>
    </div>
    <div className="text-gray-600 flex items-center">
      <FaCoins className="mr-2 text-yellow-500" />
      <span>Tokens: {document.tokens_used}</span>
    </div>
  </div>
);

function UserDocuments() {
  const [user, setUser] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userId } = useParams();

  useEffect(() => {
    const fetchUserDocuments = async () => {
      try {
        const token = cookies.get('token');
        const response = await fetch(`https://www.icromm.com/api/admin/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user documents');
        }
        const data = await response.json();
        setUser(data.user);
        setDocuments(data.documents);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchUserDocuments();
  }, [userId]);

  if (loading) return <div className="text-center mt-8">Loading...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">Error: {error}</div>;

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <Link to="/admin-dashboard/all-users" className="flex items-center text-blue-500 hover:text-blue-700 mb-6">
        <FaArrowLeft className="mr-2" />
        Back to All Users
      </Link>
      <h2 className="text-3xl font-bold mb-6 text-gray-800">
        Documents for {user.first_name} {user.last_name}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {documents.map((doc) => (
          <DocumentCard key={doc.id} document={doc} />
        ))}
      </div>
    </div>
  );
}

export default UserDocuments;
