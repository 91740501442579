import React, { useEffect, useState, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  TextField,
  Card,
  Box,
  TextareaAutosize,
  InputLabel,
  IconButton,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FileText } from "lucide-react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import "@fontsource/roboto";
import { FormContext } from "../context/FormContext";

const RiskManagementScheduleCard = ({ color, q2 }) => {
  const { riskManagementScheduleData, handleRiskManagementScheduleChange } = useContext(FormContext);

  const [sections, setSections] = useState([
    {
      id: 0,
      no: "",
      activity: "",
      initialRisk: "",
      hierarchy: "",
      controlsProcedures: "",
      residualRisk: "",
      personsResponsible: "", // Ensure this field is included
    },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const riskRatingColors = {
    "H-11": { backgroundColor: "#ffc000", textColor: "black" },
    "H-16": { backgroundColor: "#ffc000", textColor: "black" },
    "E-20": { backgroundColor: "red", textColor: "white" },
    "E-23": { backgroundColor: "red", textColor: "white" },
    "E-25": { backgroundColor: "red", textColor: "white" },
    "M-7": { backgroundColor: "#ffd966", textColor: "black" },
    "H-12": { backgroundColor: "#ffc000", textColor: "black" },
    "H-17": { backgroundColor: "#ffc000", textColor: "black" },
    "E-21": { backgroundColor: "red", textColor: "white" },
    "E-24": { backgroundColor: "red", textColor: "white" },
    "L-4": { backgroundColor: "#a9d08e", textColor: "black" },
    "M-8": { backgroundColor: "#ffd966", textColor: "black" },
    "H-13": { backgroundColor: "#ffc000", textColor: "black" },
    "E-18": { backgroundColor: "red", textColor: "white" },
    "E-22": { backgroundColor: "red", textColor: "white" },
    "L-2": { backgroundColor: "#a9d08e", textColor: "black" },
    "L-5": { backgroundColor: "#a9d08e", textColor: "black" },
    "M-9": { backgroundColor: "#ffd966", textColor: "black" },
    "H-14": { backgroundColor: "#ffc000", textColor: "black" },
    "E-19": { backgroundColor: "red", textColor: "white" },
    "L-1": { backgroundColor: "#a9d08e", textColor: "black" },
    "L-3": { backgroundColor: "#a9d08e", textColor: "black" },
    "M-6": { backgroundColor: "#ffd966", textColor: "black" },
    "H-10": { backgroundColor: "#ffc000", textColor: "black" },
    "H-15": { backgroundColor: "#ffc000", textColor: "black" },
  };

  const HierarchyColor = {
    1: { backgroundColor: "#a9d08e", textColor: "black" },
    2: { backgroundColor: "#ffd966", textColor: "black" },
    3: { backgroundColor: "red", textColor: "white" },
  };

  useEffect(() => {
    if (Array.isArray(q2) && q2.length > 0) {
      handleRiskManagementScheduleChange(q2);
    } else if (riskManagementScheduleData.length === 0) {
      handleRiskManagementScheduleChange([{
        id: 0,
        no: "",
        activity: "",
        riskDescription: "",
        initialRisk: "",
        hierarchy: "",
        controlsProcedures: "",
        residualRisk: "",
        personsResponsible: "",
      }]);
    }
  }, [q2]);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleAddSection = () => {
    const newSection = {
      id: riskManagementScheduleData.length,
      no: "",
      activity: "",
      initialRisk: "",
      hierarchy: "",
      controlsProcedures: "",
      residualRisk: "",
      personsResponsible: "", // Ensure this field is included
    };
    handleRiskManagementScheduleChange([...riskManagementScheduleData, newSection]);
  };

  const handleDeleteSection = (id) => {
    if (riskManagementScheduleData.length > 1) {
      const updatedSections = riskManagementScheduleData.filter((section) => section.id !== id);
      handleRiskManagementScheduleChange(updatedSections);
    }
  };

  const handleSectionChange = (id, e) => {
    const { name, value } = e.target;
    const updatedSections = riskManagementScheduleData.map((section) =>
      section.id === id ? { ...section, [name]: value } : section
    );
    handleRiskManagementScheduleChange(updatedSections);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${color}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handlePopoverClick(e);
                }
              }}
              sx={{ padding: 0, color: "black" }}
            >
              <FileText />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handlePopoverClick(e);
                }
              }}
            >
              Risk Management Schedule
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": { paddingBottom: 0 },
            }}
          >
            {riskManagementScheduleData.map((section) => (
              <Box
                key={section.id}
                sx={{
                  border: `2px solid ${color}`,
                  padding: 2,
                  borderRadius: "3px",
                  mb: 2,
                  position: "relative",
                  "&:last-child": {
                    mb: 0,
                  },
                }}
              >
                {showDeleteIcons && riskManagementScheduleData.length > 1 && (
                  <IconButton
                    onClick={() => handleDeleteSection(section.id)}
                    sx={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      padding: "0",
                      color: "red",
                    }}
                  >
                    <CancelIcon sx={{ fontSize: "15px" }} />
                  </IconButton>
                )}
                <Box
                  sx={{
                    border: `2px solid #C7C9CE`,
                    mb: 2,
                    padding: 2,
                    borderRadius: "3px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <InputLabel
                        htmlFor={`no-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        No
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`no-${section.id}`}
                        name="no"
                        value={section.no}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor: "white",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor={`activity-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Activity
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`activity-${section.id}`}
                        name="activity"
                        value={section.activity}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor: "white",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel
                        htmlFor={`initialRisk-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Initial Risk
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`initialRisk-${section.id}`}
                        name="initialRisk"
                        value={section.initialRisk}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor:
                            riskRatingColors[section.initialRisk.trim()]
                              ?.backgroundColor || "white",
                          color:
                            riskRatingColors[section.initialRisk.trim()]
                              ?.textColor || "black",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-input": {
                            color:
                              riskRatingColors[section.initialRisk.trim()]
                                ?.textColor || "black",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <InputLabel
                        htmlFor={`hierarchy-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Hierarchy
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`hierarchy-${section.id}`}
                        name="hierarchy"
                        value={section.hierarchy}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor:
                            HierarchyColor[section.hierarchy.trim()]
                              ?.backgroundColor || "white",
                          color:
                            HierarchyColor[section.hierarchy.trim()]
                              ?.textColor || "black",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-input": {
                            color:
                              HierarchyColor[section.hierarchy.trim()]
                                ?.textColor || "black",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    border: `2px solid #C7C9CE`,
                    mb: 2,
                    padding: 2,
                    borderRadius: "3px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel
                        htmlFor={`controlsProcedures-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Controls and Procedures
                      </InputLabel>
                      <TextareaAutosize
                        id={`controlsProcedures-${section.id}`}
                        name="controlsProcedures"
                        value={section.controlsProcedures}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        minRows={4}
                        style={{
                          minWidth: "98.5%",
                          maxWidth: "98.5%",
                          padding: "8px",
                          borderColor: "grey",
                          borderRadius: "5px",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: "16px",
                          color: "#424242",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    border: `2px solid #C7C9CE`,
                    padding: 2,
                    borderRadius: "3px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel
                        htmlFor={`residualRisk-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Residual Risk
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`residualRisk-${section.id}`}
                        name="residualRisk"
                        value={section.residualRisk}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor:
                            riskRatingColors[section.residualRisk.trim()]
                              ?.backgroundColor || "white",
                          color:
                            riskRatingColors[section.residualRisk.trim()]
                              ?.textColor || "black",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-input": {
                            color:
                              riskRatingColors[section.residualRisk.trim()]
                                ?.textColor || "black",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={9}>
                      <InputLabel
                        htmlFor={`personsResponsible-${section.id}`}
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: "bold",
                          color: "#424242",
                        }}
                      >
                        Persons Responsible
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`personsResponsible-${section.id}`}
                        name="personsResponsible"
                        value={section.personsResponsible}
                        onChange={(e) => handleSectionChange(section.id, e)}
                        variant="outlined"
                        size="small"
                        sx={{
                          bgcolor: "white",
                          fontFamily: "Roboto, sans-serif",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
            <Popover
              id={id}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  position: "relative",
                }}
              >
                {/* Top-right Close Button with small size */}
                <IconButton
                  aria-label="close"
                  onClick={handlePopoverClose}
                  size="small" // Makes the close button smaller
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    color: "grey.500",
                  }}
                >
                  <CloseIcon fontSize="small" />{" "}
                  {/* Optional: Adjust icon size */}
                </IconButton>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDeleteIcons}
                      onChange={() => setShowDeleteIcons(!showDeleteIcons)}
                      sx={{
                        color: color,
                        "&.Mui-checked": {
                          color: color,
                        },
                      }}
                    />
                  }
                  label="Delete Schedule"
                />
                <Button
                  variant="contained"
                  onClick={handleAddSection}
                  sx={{
                    bgcolor: color,
                    fontFamily: "Roboto, sans-serif",
                    color: "black",
                    borderRadius: "3px",
                    textTransform: "none",
                    boxShadow: 3,
                    "&:hover": {
                      boxShadow: 4,
                    },
                    "&:active": {
                      boxShadow: 2,
                    },
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Schedule
                </Button>
              </Box>
            </Popover>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default RiskManagementScheduleCard;
