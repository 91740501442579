import React, { useEffect, useState } from "react";
import AdminLayout from "../components/AdminLayout"; // Import AdminLayout
import { Check, X } from "lucide-react";

function UserRequests() {
  const [requests, setRequests] = useState([]); // State to hold requests
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchPendingUsers = async () => {
      try {
        const response = await fetch("https://www.icromm.com/api/users/pending");
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        setRequests(data.pending_users); // Set fetched users to state
      } catch (error) {
        setError(error.message); // Set error message if fetch fails
      } finally {
        setLoading(false); // Set loading to false regardless of outcome
      }
    };

    fetchPendingUsers(); // Call the fetch function
  }, []);

  const handleApprove = async (userId) => {
    try {
      const response = await fetch(`https://www.icromm.com/api/users/approve/${userId}`, {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error("Failed to approve user");
      }
      const updatedRequests = requests.filter((request) => request._id !== userId);
      setRequests(updatedRequests);
      alert("User approved successfully");
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDecline = async (userId) => {
    try {
      const response = await fetch(`https://www.icromm.com/api/users/decline/${userId}`, {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error("Failed to decline user");
      }
      const updatedRequests = requests.filter((request) => request._id !== userId);
      setRequests(updatedRequests);
      alert("User declined successfully");
    } catch (error) {
      alert(error.message);
    }
  };

  if (loading) return <div>Loading...</div>; // Display loading text
  if (error) return <div>Error: {error}</div>; // Display error if there is one

  return (
    <div className="h-full overflow-auto">
      <h1 className="mb-4 text-2xl font-bold">All Requests</h1>
      {requests.length === 0 ? (
        <div className="text-center text-gray-600 py-8">
          No pending user requests available
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {requests.map((request) => (
            <div
              key={request._id}
              className="bg-white p-5 rounded-lg shadow-md border border-gray-200 transition-transform duration-200 hover:shadow-lg hover:transform hover:scale-105 flex flex-col justify-between space-y-4"
            >
              <div>
                <h2 className="text-lg font-semibold text-gray-800">
                  {request.first_name} {request.last_name}
                </h2>
                <p className="text-sm text-gray-600">{request.company}</p>
                <p className="text-sm text-gray-600">{request.email}</p>
                <p className="text-sm text-gray-600">{request.phone}</p>
              </div>
              <div className="flex justify-between items-center space-x-4">
                <button
                  onClick={() => handleApprove(request._id)} // Approve button
                  className="flex items-center space-x-2 px-4 py-2 text-green-600 border border-green-600 rounded-lg shadow-sm hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-150 ease-in-out"
                >
                  <Check className="h-4 w-4" />
                  <span className="text-sm">Approve</span>
                </button>
                <button
                  onClick={() => handleDecline(request._id)} // Decline button
                  className="flex items-center space-x-2 px-4 py-2 text-red-600 border border-red-600 rounded-lg shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-150 ease-in-out"
                >
                  <X className="h-4 w-4" />
                  <span className="text-sm">Decline</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserRequests;
