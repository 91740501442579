import React, { useState, useContext, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  IconButton,
  Typography,
  Popover,
  Checkbox,
  Card,
  Box,
  FormControlLabel,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormContext } from "../context/FormContext";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { CircleAlert } from "lucide-react";
import DeleteIcon from "@mui/icons-material/Delete";

const InductionSignOnAccordion = ({ color }) => {
  const { inductionSignOnData, handleInductionSignOnChange } =
    useContext(FormContext);

  const [rowsPage1, setRowsPage1] = useState([]);

  useEffect(() => {
    let initialRows = inductionSignOnData.rowsPage1 || [];
    
    // Ensure there are at least 3 rows
    while (initialRows.length < 3) {
      initialRows.push({ name: "", company: "", signature: "", date: "" });
    }

    setRowsPage1(initialRows);
    
    // Update context if the rows have changed
    if (JSON.stringify(initialRows) !== JSON.stringify(inductionSignOnData.rowsPage1)) {
      handleInductionSignOnChange({ rowsPage1: initialRows });
    }
  }, [inductionSignOnData, handleInductionSignOnChange]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;
  const [expanded, setExpanded] = useState(false); // Track accordion expansion

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const updateInductionSignOnData = (updatedRows) => {
    handleInductionSignOnChange({ rowsPage1: updatedRows });
  };

  const handleAddRowPage1 = () => {
    const newRows = [
      ...rowsPage1,
      { name: "", company: "", signature: "", date: "" },
    ];
    setRowsPage1(newRows);
    updateInductionSignOnData(newRows);
  };

  const handleDeleteRow = (table, index) => {
    if (table === "page1") {
      const updatedRows = rowsPage1.filter((_, rowIndex) => rowIndex !== index);
      // Ensure we always have at least 3 rows
      while (updatedRows.length < 3) {
        updatedRows.push({ name: "", company: "", signature: "", date: "" });
      }
      setRowsPage1(updatedRows);
      updateInductionSignOnData(updatedRows);
    }
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = rowsPage1.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRowsPage1(updatedRows);
    updateInductionSignOnData(updatedRows);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${color}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "80px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              width: "100%",
            }}
          >
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  e.stopPropagation();
                  handlePopoverOpen(e);
                }
              }}
              sx={{
                padding: 0,
                color: "black",
                mr: 1, // Add margin-right to space out from the text
              }}
            >
              <CircleAlert />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                }}
                onClick={(e) => {
                  if (expanded) {
                    e.stopPropagation();
                    handlePopoverOpen(e);
                  }
                }}
              >
                Employee induction– Statement of agreement
              </Typography>
              {expanded && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.875rem", // Slightly smaller font size for the description
                    lineHeight: 1.4, // Increase line height for readability
                  }}
                >
                  On signing this SWMS, I agree that I have been consulted,
                  understand, and agree to abide by the control measures listed
                  in all the pages contained within this SWMS.
                </Typography>
              )}
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              {/* Table for Page 1 */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: `2px solid ${color}`,
                    padding: 2,
                    marginBottom: 0,
                    borderRadius: "3px",
                    position: "relative",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow sx={{ bgcolor: color }}>
                        <TableCell
                          colSpan={4}
                          sx={{
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Induction Sign On Page
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ alignItems: "center" }}>
                        <TableCell
                          sx={{
                            bgcolor: color,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: color,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Company
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: color,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Signature
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: color,
                            border: "1px solid black",
                            fontWeight: "bold",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inductionSignOnData.rowsPage1.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.name}
                              size="small"
                              fullWidth
                              onChange={(e) =>
                                handleRowChange(index, "name", e.target.value)
                              }
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Name"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.company}
                              size="small"
                              fullWidth
                              onChange={(e) =>
                                handleRowChange(
                                  index,
                                  "company",
                                  e.target.value
                                )
                              }
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Company"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.signature}
                              size="small"
                              fullWidth
                              onChange={(e) =>
                                handleRowChange(
                                  index,
                                  "signature",
                                  e.target.value
                                )
                              }
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Signature"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ border: "1px solid black", padding: 1 }}
                          >
                            <TextField
                              value={row.date}
                              size="small"
                              name="date"
                              type="date"
                              fullWidth
                              onChange={(e) =>
                                handleRowChange(index, "date", e.target.value)
                              }
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Date"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </AccordionDetails>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              size="small"
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "grey.500",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDeleteIcon}
                  onChange={() => setShowDeleteIcon(!showDeleteIcon)}
                  sx={{
                    color: color,
                    "&.Mui-checked": {
                      color: color,
                    },
                    "& .MuiCheckbox-root": {
                      color: color,
                      "&.Mui-checked": {
                        color: color,
                      },
                    },
                  }}
                />
              }
              label="Delete Rows"
            />
            <Button
              onClick={handleAddRowPage1}
              startIcon={<AddIcon />}
              variant="contained"
              sx={{
                bgcolor: color,
                fontFamily: "Roboto, sans-serif",
                color: "black",
                borderRadius: "3px",
                textTransform: "none",
                boxShadow: 3,
                "&:hover": {
                  boxShadow: 4,
                },
                "&:active": {
                  // background: 'linear-gradient(150deg, #6096B4 0%, #000 100%)',
                  boxShadow: 2,
                },
              }}
            >
              Add Row to Page 1
            </Button>
          </Box>
        </Popover>
      </Accordion>
    </Card>
  );
};

export default InductionSignOnAccordion;
