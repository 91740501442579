import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import backgroundVideo from "../assets/icrommBG.mp4";
import Logo from "../assets/icrommLogoWhiteLarge.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1); // 1: email, 2: OTP, 3: new password
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const API_URL = 'https://www.icromm.com/api';

  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('email', email);
      
      await axios.post(`${API_URL}/forgot-password`, formData);
      setMessage('OTP sent successfully');
      setStep(2);
    } catch (error) {
      setMessage(error.response?.data?.detail || 'Failed to send OTP');
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('otp', otp);
      
      await axios.post(`${API_URL}/verify-otp`, formData);
      setMessage('OTP verified successfully');
      setStep(3);
    } catch (error) {
      setMessage(error.response?.data?.detail || 'Invalid OTP');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('new_password', newPassword);
      formData.append('confirm_password', confirmPassword);
      
      await axios.post(`${API_URL}/reset-password`, formData);
      setMessage('Password reset successfully');
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setMessage(error.response?.data?.detail || 'Failed to reset password');
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      />

      <div className="relative flex flex-col items-center">
        <img src={Logo} alt="ICROMM Logo" className="w-[150px] mb-6 z-10 pl-2 py-1" />

        <div className="relative w-[400px] bg-white rounded-lg shadow-lg p-6 z-10">
          <div className="space-y-1 text-center">
            <h2 className="text-2xl font-semibold">Reset Password</h2>
            <p className="text-sm text-gray-500">
              {step === 1 && "Enter your email to receive OTP"}
              {step === 2 && "Enter the OTP sent to your email"}
              {step === 3 && "Enter your new password"}
            </p>
          </div>

          <div className="mt-4">
            {step === 1 && (
              <form onSubmit={handleSendOTP} className="space-y-4">
                <div className="space-y-2">
                  <label className="block text-sm font-medium">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full rounded-lg bg-blue-900 text-white p-2 hover:bg-blue-800"
                >
                  Send OTP
                </button>
              </form>
            )}

            {step === 2 && (
              <form onSubmit={handleVerifyOTP} className="space-y-4">
                <div className="space-y-2">
                  <label className="block text-sm font-medium">Enter OTP</label>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    className="block w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full rounded-lg bg-blue-900 text-white p-2 hover:bg-blue-800"
                >
                  Verify OTP
                </button>
              </form>
            )}

            {step === 3 && (
              <form onSubmit={handleResetPassword} className="space-y-4">
                <div className="space-y-2">
                  <label className="block text-sm font-medium">New Password</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    className="block w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium">Confirm Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="block w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full rounded-lg bg-blue-900 text-white p-2 hover:bg-blue-800"
                >
                  Reset Password
                </button>
              </form>
            )}

            {message && (
              <p className={`text-center mt-4 ${
                message.includes('successfully') ? 'text-green-600' : 'text-red-600'
              }`}>
                {message}
              </p>
            )}

            <div className="mt-4 text-center text-sm">
              <a href="/login" className="text-blue-900 hover:underline">
                Back to Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;