import React, { useState } from 'react';
import axios from 'axios';
import { Eye, EyeOff } from 'lucide-react';
import backgroundVideo from '../assets/icrommBG.mp4';
import Logo from "../assets/icrommLogoWhiteLarge.png";

export default function UserSignup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswords, setShowPasswords] = useState(false);
  const [message, setMessage] = useState('');
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [otp, setOtp] = useState('');

  // API base URL
  const API_URL = 'https://www.icromm.com/api';

  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessage('');

    if (password !== confirmPassword) {
      setMessage('Passwords do not match!');
      return;
    }

    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      company: company,
      phone: phone,
      password: password,
      confirm_password: confirmPassword,
    };

    try {
      const response = await axios.post(`${API_URL}/signup`, userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.message) {
        setMessage('Please check your email for verification code');
        setShowOtpForm(true);
      }
    } catch (error) {
      if (error.response && error.response.data.detail) {
        setMessage(error.response.data.detail);
      } else {
        setMessage('An error occurred. Please try again later.');
      }
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setMessage('');

    const formData = new FormData();
    formData.append('email', email);
    formData.append('otp', otp);

    try {
      const response = await axios.post(`${API_URL}/verify-signup`, formData);
      if (response.data.message) {
        setMessage('Account created successfully!');
        // Redirect to login page after 2 seconds
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.data.detail) {
        setMessage(error.response.data.detail);
      } else {
        setMessage('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30"></div>

      <div className="relative flex flex-col items-center">
        <img src={Logo} alt="ICROMM Logo" className="w-[150px] mb-6 z-10 pl-2 py-1" />

        <div className="relative w-[400px] bg-white rounded-lg shadow-lg p-6 z-10">
          <h2 className="text-2xl font-semibold text-center mb-4">Create an Account</h2>
          <p className="text-center text-gray-600 mb-6">Enter your information to sign up</p>
          {!showOtpForm ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* First Name and Last Name side by side */}
              <div className="flex space-x-4">
                <div className="flex-1 space-y-2">
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="flex-1 space-y-2">
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Company
                </label>
                <input
                  id="company"
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter your company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  id="phone"
                  type="tel"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>

              {/* Password Input */}
              <div className="space-y-2 relative">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  id="password"
                  type={showPasswords ? 'text' : 'password'}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Create a password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  onClick={() => setShowPasswords(!showPasswords)}
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                >
                  {showPasswords ? <EyeOff size={20} className="mt-4 mr-1" /> : <Eye size={20} className="mt-4 mr-1" />}
                </span>
              </div>

              {/* Confirm Password Input */}
              <div className="space-y-2 relative">
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  type={showPasswords ? 'text' : 'password'}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <span
                  onClick={() => setShowPasswords(!showPasswords)}
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                >
                  {showPasswords ? <EyeOff size={20} className="mt-4 mr-1" /> : <Eye size={20} className="mt-4 mr-1" />}
                </span>
              </div>

              {/* Error/Success Message Display */}
              {message && (
                <p className={`text-center mt-4 ${message.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
                  {message}
                </p>
              )}

              <button
                type="submit"
                className="w-full bg-blue-900 text-white py-2 rounded-md hover:bg-blue-800"
              >
                Sign Up
              </button>
            </form>
          ) : (
            <form onSubmit={handleOtpSubmit} className="space-y-4">
              <div className="space-y-2">
                <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                  Enter Verification Code
                </label>
                <input
                  id="otp"
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter 6-digit code"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>

              {message && (
                <p className={`text-center mt-4 ${message.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
                  {message}
                </p>
              )}

              <button
                type="submit"
                className="w-full bg-blue-900 text-white py-2 rounded-md hover:bg-blue-800"
              >
                Verify
              </button>
            </form>
          )}
          <div className="mt-4 text-center text-sm">
            Already have an account?{' '}
            <a href="/login" className="text-blue-900 hover:underline">
              Log in
            </a>
          </div>
        </div>

        {/* Company Name Text */}
        <div className="mt-4 p-2 rounded-lg text-center z-10">
          <p className="text-white text-sm">ICROMM Pty Ltd.</p>
        </div>
      </div>
    </div>
  );
}
