
import React from 'react';
import Login from '../components/Login';
import '../styles/LoginPage.css'; 

const LoginPage = () => {
  return (
    <Login />
  );
};

export default LoginPage;