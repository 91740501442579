import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { FaCheckCircle, FaTimesCircle, FaBuilding, FaEnvelope, FaUser } from 'react-icons/fa';

const cookies = new Cookies();

const UserCard = ({ user, onClick }) => (
  <div
    className="bg-white p-6 rounded-lg shadow-xl hover:scale-105 transform transition-all duration-300 cursor-pointer"
    onClick={() => onClick(user.id)}
  >
    <div className="flex items-center mb-4">
      <FaUser className="text-blue-600 mr-3 text-3xl" />
      <h3 className="text-xl font-extrabold text-gray-800">
        {user.first_name} {user.last_name}
      </h3>
    </div>
    <div className="text-gray-700 mb-2 flex items-center">
      <FaEnvelope className="mr-2 text-gray-500" />
      <span>{user.email}</span>
    </div>
    <div className="text-gray-700 mb-2 flex items-center">
      <FaBuilding className="mr-2 text-gray-500" />
      <span>Company: {user.company}</span>
    </div>
    <div className="flex items-center mb-2">
      {user.status === 'active' ? (
        <FaCheckCircle className="text-green-500 mr-2" />
      ) : (
        <FaTimesCircle className="text-red-500 mr-2" />
      )}
      <span>Status: {user.status}</span>
    </div>
    <p
      className={`font-semibold mb-2 ${
        user.payment_status === 'Paid' ? 'text-green-600' : 'text-red-600'
      }`}
    >
      Payment Status: {user.payment_status}
    </p>
    <p className="text-gray-800 font-semibold">
      Total Tokens Used: <span className="text-blue-700">{user.lifetime_tokens_used}</span>
    </p>
  </div>
);

function DisplayAllUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = cookies.get('token');
      const response = await fetch('https://www.icromm.com/api/admin/all-users', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      const filteredUsers = data.filter((user) => !user.is_superadmin);
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleUserClick = (userId) => {
    navigate(`/admin/users/${userId}/documents`);
  };

  if (loading) return <div className="text-center mt-8">Loading...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">Error: {error}</div>;

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold text-center mb-10 text-gray-800">All Users</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {users.map((user) => (
          <UserCard key={user.id} user={user} onClick={handleUserClick} />
        ))}
      </div>
    </div>
  );
}

export default DisplayAllUsers;
