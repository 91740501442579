import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider component to manage authentication state and token
export const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [authToken, setAuthToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // On initial load, check for the token in cookies
  useEffect(() => {
    const storedToken = Cookies.get('token'); // Retrieve token from cookies
    if (storedToken) {
      setAuthToken(storedToken);
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  // Function to log in and set the token in cookies
  const login = (token) => {
    Cookies.set('token', token, { expires: 30 }); // Store token in cookies with an expiration of 1 days
    setAuthToken(token); // Update state with token
    setIsAuthenticated(true); // Update authentication status
  };

  // Function to log out and clear the token
  const logout = () => {
    Cookies.remove('token'); // Remove token from cookies
    setAuthToken(null); // Clear the state
    setIsAuthenticated(false); // Update authentication status
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext values
export const useAuth = () => useContext(AuthContext);