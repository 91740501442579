import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  Card,
  Box,
  InputLabel,
  TextareaAutosize,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
  Popover,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import "@fontsource/roboto";
import { FormContext } from "../../context/FormContext";

const HighLevelRisksCard = ({ color, q1 }) => {
  const { highLevelRisksData, handleHighLevelRiskChange, documentColor } = useContext(FormContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (q1 && q1.length > 0) {
      const processedRisks = q1.map((item, index) => ({
        id: `risk-${index}`,
        risk: item.Risk || "",
        explanation: item.Explanation || "",
        codeOfPractice: item["Code of Practice"] || "",
      }));
      handleHighLevelRiskChange(processedRisks);
    } else if (highLevelRisksData.length === 0) {
      handleHighLevelRiskChange([{
        id: 'risk-0',
        risk: "",
        explanation: "",
        codeOfPractice: "",
      }]);
    }
  }, [q1]);

  const handleInputChange = (id, field, value) => {
    // console.log('Handling input change:', { id, field, value });
    const updatedRisks = highLevelRisksData.map(risk => {
      if (risk.id === id) {
        return { ...risk, [field]: value };
      }
      return risk;
    });
    // console.log('Updated risks:', updatedRisks);
    handleHighLevelRiskChange(updatedRisks);
  };

  const handleAddDescription = () => {
    const newId = `risk-${highLevelRisksData.length}`;
    const newRisk = {
      id: newId,
      risk: "",
      explanation: "",
      codeOfPractice: "",
    };
    handleHighLevelRiskChange([...highLevelRisksData, newRisk]);
  };

  const handleDeleteRisk = (id) => {
    if (highLevelRisksData.length > 1) {
      const updatedRisks = highLevelRisksData
        .filter(risk => risk.id !== id)
        .map((risk, index) => ({
          ...risk,
          id: `risk-${index}`
        }));
      handleHighLevelRiskChange(updatedRisks);
    }
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  const extractKeywords = (url) => {
    // Remove common prefixes and file extensions
    const cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\.[^/.]+$/, '');
    // Split the remaining string into words
    const words = cleanUrl.split(/[-.]+/);
    // Return up to 3 words, joined with spaces
    return words.slice(0, 3).join(' ');
  };

  const handleLinkClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${documentColor}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: documentColor,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
              sx={{ padding: 0, color: "black" }}
            >
              <WarningAmberIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
            >
              High Level Risks
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            <Grid container spacing={2}>
              {highLevelRisksData.map((risk, index) => (
                <Grid item xs={4} key={risk.id}>
                  <Box sx={{
                    border: `2px solid ${documentColor}`,
                    padding: 2,
                    marginBottom: 0,
                    borderRadius: "3px",
                    position: "relative",
                  }}>
                    {showDeleteIcons && highLevelRisksData.length > 1 && (
                      <IconButton
                        onClick={() => handleDeleteRisk(risk.id)}
                        sx={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          padding: "0",
                          color: "red",
                        }}
                      >
                        <CancelIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    )}

                    <InputLabel
                      htmlFor={`risk-${risk.id}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      High Level Risk {index + 1}
                    </InputLabel>

                    <InputLabel
                      htmlFor={`risk-name-${risk.id}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                        marginTop: "16px",
                      }}
                    >
                      Name of the Risk
                    </InputLabel>
                    <TextareaAutosize
                      id={`risk-name-${risk.id}`}
                      name="risk"
                      value={risk.risk || ''}
                      onChange={(e) => handleInputChange(risk.id, "risk", e.target.value)}
                      minRows={2}
                      style={{
                        minWidth: "95%",
                        maxWidth: "95%",
                        padding: "8px",
                        fontFamily: "Roboto, sans-serif",
                        borderRadius: "5px",
                        borderColor: "grey",
                      }}
                    />

                    <InputLabel
                      htmlFor={`explanation-${risk.id}`}
                      sx={{
                        fontSize: "12px",
                        marginTop: "16px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      Explanation
                    </InputLabel>
                    <TextareaAutosize
                      id={`explanation-${risk.id}`}
                      name="explanation"
                      value={risk.explanation || ''}
                      onChange={(e) => handleInputChange(risk.id, "explanation", e.target.value)}
                      minRows={2}
                      style={{
                        minWidth: "95%",
                        maxWidth: "95%",
                        padding: "8px",
                        fontFamily: "Roboto, sans-serif",
                        borderRadius: "5px",
                        borderColor: "grey",
                      }}
                    />

                    <InputLabel
                      htmlFor={`codeOfPractice-${risk.id}`}
                      sx={{
                        fontSize: "12px",
                        marginTop: "16px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      Code of practice
                    </InputLabel>
                    <TextareaAutosize
                      id={`codeOfPractice-${risk.id}`}
                      name="codeOfPractice"
                      value={risk.codeOfPractice || ''}
                      onChange={(e) => handleInputChange(risk.id, "codeOfPractice", e.target.value)}
                      minRows={2}
                      style={{
                        minWidth: "95%",
                        maxWidth: "95%",
                        padding: "8px",
                        fontFamily: "Roboto, sans-serif",
                        borderRadius: "5px",
                        borderColor: "grey",
                        cursor: risk.codeOfPractice && risk.codeOfPractice.startsWith('http') ? 'pointer' : 'auto',
                        color: risk.codeOfPractice && risk.codeOfPractice.startsWith('http') ? 'blue' : 'inherit',
                        textDecoration: risk.codeOfPractice && risk.codeOfPractice.startsWith('http') ? 'underline' : 'none',
                      }}
                    />
                    {risk.codeOfPractice && risk.codeOfPractice.startsWith('http') && (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          mt: 1,
                          color: 'text.secondary',
                          fontStyle: 'italic'
                        }}
                      >
                        {extractKeywords(risk.codeOfPractice)}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: color,
                  "&.Mui-checked": {
                    color: documentColor,
                  },
                  "& .MuiCheckbox-root": {
                    color: color,
                    "&.Mui-checked": {
                      color: documentColor,
                    },
                  },
                }}
              />
            }
            label="Delete High Level Risk"
          />

          <Button
            variant="contained"
            component="span"
            sx={{
              bgcolor: documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            onClick={handleAddDescription}
            startIcon={<AddIcon />}
          >
            Add High Level Risk
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default HighLevelRisksCard;
