import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Logo from "../assets/icrommLogoBlueLarge.png";
import {
  ChevronLeft,
  ChevronRight,
  BellPlus,
  Plus,
  Search,
} from "lucide-react";

function AdminDashboard() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  return (
    <div>
      {/* Dashboard content */}
      <main className="flex-1 overflow-y-auto p-7">
        <div className="h-full overflow-auto">
          <h1 className="mb-4 text-2xl font-bold">Dashboard</h1>
          {/* Add your dashboard content here */}
          <p>
            Welcome to your dashboard. This is where you can manage your
            documents and settings.
          </p>
        </div>
      </main>
    </div>
  );
}

export default AdminDashboard;
