import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import Logo from "../assets/icrommLogoBlueLarge.png";
import { ChevronLeft, ChevronRight, BellPlus, CheckCircle, XCircle, LogOut, Users } from "lucide-react"; 
import { useUser } from "../context/UserContext"; // Import UserContext
import { useAuth } from "../context/AuthContext"; // Import AuthContext

const AdminLayout = ({ children }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); 
  const location = useLocation();
  const navigate = useNavigate();
  
  const { user, loading } = useUser(); // Access user data from UserContext
  const { logout } = useAuth(); // Get logout function from AuthContext

  // Add this console.log to see what's in the user object
  // console.log("User in AdminLayout:", user);

  const isActiveLink = (path) => location.pathname === path;

  const handleLogout = () => {
    setDropdownOpen(false); // Close the dropdown first
    logout(); // Call logout function from AuthContext
    navigate("/login"); // Redirect to home page
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div
        className={`bg-white transition-all duration-300 ease-in-out shadow-md ${isSidebarCollapsed ? "w-16" : "w-48"}`}
      >
        <div className="flex items-center mt-3 justify-between p-4">
          <h2 className={`text-2xl mt-3 ml-3 font-bold ${isSidebarCollapsed ? "hidden" : "block"}`}>
            <Link to="/admin-dashboard">Menu</Link>
          </h2>
          <button
            className="p-1 rounded-full hover:bg-gray-200 focus:outline-none"
            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
            aria-label={isSidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {isSidebarCollapsed ? (
              <ChevronRight className="h-4 w-4" />
            ) : (
              <ChevronLeft className="h-4 w-4" />
            )}
          </button>
        </div>
        <nav className="space-y-5 mt-2 p-2">
          <Link
            to="/admin-dashboard/all-users"
            className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 ${isActiveLink("/admin-dashboard/all-users") ? "bg-gray-100" : ""}`}
          >
            <Users className="h-5 w-5" />
            {!isSidebarCollapsed && <span>All Users</span>}
          </Link>
          <Link
            to="/admin-dashboard/requests"
            className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 ${isActiveLink("/admin-dashboard/requests") ? "bg-gray-100" : ""}`}
          >
            <BellPlus className="h-5 w-5" />
            {!isSidebarCollapsed && <span>Users Requests</span>}
          </Link>
          <Link
            to="/admin-dashboard/approve-requests"
            className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 ${isActiveLink("/admin-dashboard/approve-requests") ? "bg-gray-100" : ""}`}
          >
            <CheckCircle className="h-5 w-5" /> 
            {!isSidebarCollapsed && <span>Approved Requests</span>}
          </Link>
          <Link
            to="/admin-dashboard/decline-requests"
            className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 ${isActiveLink("/admin-dashboard/decline-requests") ? "bg-gray-100" : ""}`}
          >
            <XCircle className="h-5 w-5" />
            {!isSidebarCollapsed && <span>Declined Requests</span>}
          </Link>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex flex-1 flex-col overflow-hidden">
        {/* Header */}
        <header className="flex items-center pr-9 justify-between bg-white p-4 shadow">
          <div className="flex-1 text-center">
            <img src={Logo} alt="Logo" className="mx-auto h-14" />
          </div>
          <div className="relative flex items-center space-x-4">
            <span 
              className="text-md font-medium cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {loading 
                ? "Loading..." 
                : user 
                  ? `${user.first_name} ${user.last_name}`
                  : "Username"
              }
            </span>
            <img src={Logo} alt="User" className="h-8 w-8 rounded-full" />
            {/* Dropdown */}
            {dropdownOpen && (
              <div className="absolute z-10 right-0 mt-20 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
                <button
                  className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={handleLogout}
                >
                  <LogOut className="h-5 w-5 mr-2" />
                  Logout
                </button>
              </div>
            )}
          </div>
        </header>

        {/* Dashboard content */}
        <main className="flex-1 overflow-y-auto p-7">{children}</main>
      </div>
    </div>
  );
};

export default AdminLayout;
