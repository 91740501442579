import { AiFillCreditCard } from "react-icons/ai";
import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import {
  CreditCardIcon,
  UserIcon,
  MailIcon,
  HomeIcon,
  BuildingIcon,
} from "lucide-react";
import backgroundVideo from "../assets/icrommBG.mp4"; // Import the video file
import Logo from "../assets/icrommLogoWhiteLarge.png"; // Import the logo

export default function PaymentsPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.error("[error]", error);
      } else {
        // console.log("[PaymentMethod]", paymentMethod);

        try {
          const response = await axios.post(
            "https://www.icromm.com/api/payment",
            {
              payment_method_id: paymentMethod.id,
              email: email,
              first_name: firstName,
              last_name: lastName,
              billing_address: billingAddress,
              address_line_2: addressLine2,
            }
          );

          if (response.data.success) {
            console.log("Payment Successful!");
            window.location.href = "/user-dashboard";
          }
        } catch (err) {
          console.error("Payment error:", err.response.data.detail);
        }
      }
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center">
      {/* Video Background */}
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      />

      {/* Payment Form Container */}
      <div className="relative flex flex-col items-center">
        {/* Logo at the top of the card */}
        <img src={Logo} alt="ICROMM Logo" className="w-[150px] mb-4 z-10" />

        <div className="relative w-[500px] bg-white rounded-lg shadow-lg p-6 z-10">
          <div className="space-y-1 text-center mb-6">
            <AiFillCreditCard className="h-12 w-12 mx-auto text-blue-900" />
            <h2 className="text-2xl font-semibold">Payment Details</h2>
            <p className="text-gray-500">
              Please enter your payment information
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label
                  htmlFor="firstName"
                  className="flex items-center space-x-2 text-gray-700"
                >
                  <UserIcon className="h-4 w-4" />
                  <span>First Name</span>
                </label>
                <input
                  id="firstName"
                  placeholder="John"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                />
              </div>
              <div className="space-y-2">
                <label
                  htmlFor="lastName"
                  className="flex items-center space-x-2 text-gray-700"
                >
                  <UserIcon className="h-4 w-4" />
                  <span>Last Name</span>
                </label>
                <input
                  id="lastName"
                  placeholder="Doe"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label
                htmlFor="email"
                className="flex items-center space-x-2 text-gray-700"
              >
                <MailIcon className="h-4 w-4" />
                <span>Email</span>
              </label>
              <input
                id="email"
                type="email"
                placeholder="john.doe@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="billingAddress"
                className="flex items-center space-x-2 text-gray-700"
              >
                <HomeIcon className="h-4 w-4" />
                <span>Billing Address</span>
              </label>
              <input
                id="billingAddress"
                placeholder="Street, City, State, Zip"
                value={billingAddress}
                onChange={(e) => setBillingAddress(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="addressLine2"
                className="flex items-center space-x-2 text-gray-700"
              >
                <BuildingIcon className="h-4 w-4" />
                <span>Address Line 2 (Optional)</span>
              </label>
              <input
                id="addressLine2"
                placeholder="Apartment, suite, etc."
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
              />
            </div>
            <div className="space-y-2">
              <label
                htmlFor="card-element"
                className="flex items-center space-x-2 text-gray-700"
              >
                <CreditCardIcon className="h-4 w-4" />
                <span>Card Details</span>
              </label>
              <div className="p-3 border rounded-md">
                <CardElement
                  id="card-element"
                  className="focus:outline-none focus:ring-2 focus:ring-blue-900"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-900 text-white py-2 rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900"
            >
              Pay Now
            </button>
          </form>
          <div className="mt-4 text-center text-sm">
            Don't have an account?{" "}
            <a href="/signup" className="text-blue-900 hover:underline">
              Sign up
            </a>
          </div>
        </div>
        <div className="mt-4 text-center">
          <p className="text-white text-sm">ICROMM Pty Ltd.</p>
        </div>
      </div>
    </div>
  );
}
