import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FaviconManager = () => {
  const location = useLocation();
  
  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    const publicPath = process.env.PUBLIC_URL;
    
    const whiteIconRoutes = ['/', '/login', '/signup', '/payment'];
    const shouldUseWhiteIcon = whiteIconRoutes.includes(location.pathname);
    
    favicon.href = `${publicPath}/${shouldUseWhiteIcon ? 'faviconWhite.ico' : 'favicon.ico'}`;
  }, [location]);

  return null;
};

export default FaviconManager;