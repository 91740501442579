import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  CircularProgress,
  TextareaAutosize,
  Typography,
  InputLabel,
} from "@mui/material";
import { SketchPicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import { ArrowBigLeft } from "lucide-react";
import { Bot } from "lucide-react";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import qs from "qs";
import axios from "axios";
import { lighten } from "@mui/system";
import { darken } from "@mui/system";
import { useNavigate } from "react-router-dom";

// Add this style tag right after the imports
const styles = `
  .small-placeholder::placeholder {
    font-size: 14px;
  }
`;

const ColorPickerAndApiModal = ({
  open,
  onClose,
  onColorChange,
  color,
  setQ1,
  setQ2,
  setQ3,
  setQ4,
  setQ5,
  setQ6,
  setQ7,
  loading,
  setLoading,
  setPercentage,
  handleSaveDocument,
}) => {
  React.useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [formValues, setFormValues] = useState({
    task: "",
    location: "",
    includeTask: "",
    excludeTask: "",
    numberOfActivities: 22,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [min, setMin] = useState(12);
  const [max, setMax] = useState(26);
  const [accumulatedData, setAccumulatedData] = useState("");

  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleColorChange = (newColor) => {
    onColorChange(newColor.hex);
  };

  const handleClose = () => {
    setShowSketchPicker(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (formValues.task === "" || formValues.location === "") {
      setSnackbarMessage("Kindly fill the task and location fields");
      setSnackbarOpen(true);
      return;
    }

    if (!/^\d+$/.test(formValues.numberOfActivities)) {
      setSnackbarMessage(
        `Number of activities should be between ${min} and ${max}`
      );
      setSnackbarOpen(true);
      return;
    }

    if (
      formValues.numberOfActivities < min ||
      formValues.numberOfActivities > max
    ) {
      setSnackbarMessage(
        `Number of activities should be between ${min} and ${max}`
      );
      setSnackbarOpen(true);
      return;
    }

    const postData = qs.stringify({
      task: formValues.task,
      location: formValues.location,
      includeTask: formValues.includeTask,
      excludeTask: formValues.excludeTask,
      n: formValues.numberOfActivities,
    });

    try {
      const eventSource = new EventSource(
        `https://www.icromm.com/api/process_questions/?${postData}`
      );
      let messageCount = 0;
      setLoading(true);
      setPercentage(0);

      eventSource.onmessage = (event) => {
        try {
          // console.log("jsonData eventSource", event.data);
          const jsonData1 = JSON.parse(event.data);
          if (jsonData1.status === "complete") {
            // console.log("All messages have been received.");
            eventSource.close();
            setLoading(false);
            handleClose();
            return;
          }
          // const jsonData1 = JSON.parse(jsonData);
          // console.log('jsondata 1: ', jsonData1);

          messageCount++;
          switch (messageCount) {
            case 1:
              try {
                // Parse the JSON data
                const jsonData = JSON.parse(jsonData1);

                // Extract the risks from initialRiskTable
                const risks = jsonData.initialRiskTable.map((item) => ({
                  number: item.number,
                  risk: item.risk,
                  explanation: item.explanation,
                  codeOfPractice: item.codeOfPractice,
                }));

                // console.log("Parsed risks:", risks); // Debug log
                setQ1(risks);
                setPercentage(1);
              } catch (error) {
                console.error("Error parsing Q1 data:", error);
              }
              break;
            case 2:
              try {
                // Parse the JSON data if it's a string
                const riskData = JSON.parse(jsonData1);

                // Map the data to the expected format
                const sections = riskData.map((item, index) => ({
                  id: index,
                  no: item.Number?.trim() || "",
                  activity: item.Activity?.trim() || "",
                  riskDescription: item["Risk Description"]?.trim() || "",
                  initialRisk: item["Initial Risk code"]?.trim() || "",
                  hierarchy: item["Hierarchy number"]?.trim() || "",
                  controlsProcedures: Array.isArray(
                    item["Preventative Controls and Procedures"]
                  )
                    ? item["Preventative Controls and Procedures"]
                        .map((proc) => proc.trim())
                        .join("\n")
                    : item["Preventative Controls and Procedures"]?.trim() ||
                      "",
                  residualRisk: item["Residual Risk code"]?.trim() || "",
                  personsResponsible:
                    item["All People Responsible"]?.trim() || "",
                }));

                // console.log("Parsed risk management data:", sections);
                setQ2(sections);
                setPercentage(2);
              } catch (error) {
                console.error("Error parsing Q2 data:", error);
              }
              break;
            case 3:
              try {
                // Parse the JSON data
                const personsData = JSON.parse(jsonData1);
                // console.log("Raw persons data:", personsData); // Debug log

                // Map the data to the expected format
                const formattedPersons = personsData.map((person, index) => {
                  const formatted = {
                    id: index,
                    jobDescription: Array.isArray(
                      person[
                        "Job Title and Description of Person Involved in the Activity"
                      ]
                    )
                      ? person[
                          "Job Title and Description of Person Involved in the Activity"
                        ][0]
                      : person[
                          "Job Title and Description of Person Involved in the Activity"
                        ] || "",
                    rolesResponsibilities: Array.isArray(
                      person["Roles and Responsibilities of That Person"]
                    )
                      ? person[
                          "Roles and Responsibilities of That Person"
                        ].join("\n")
                      : person["Roles and Responsibilities of That Person"] ||
                        "",
                    qualificationsExperience: Array.isArray(
                      person[
                        "Qualifications & Experience Required for Person Involved in Activity"
                      ]
                    )
                      ? person[
                          "Qualifications & Experience Required for Person Involved in Activity"
                        ].join("\n")
                      : person[
                          "Qualifications & Experience Required for Person Involved in Activity"
                        ] || "",
                  };
                  // console.log(`Formatted person ${index}:`, formatted); // Debug log per person
                  return formatted;
                });

                // console.log("Final formatted persons data:", formattedPersons);
                setQ3(formattedPersons);
                setPercentage(3);
              } catch (error) {
                // console.error("Error parsing Q3 data:", error);
              }
              break;
              case 4:
                try {
                  // Skip empty initial chunk
                  if (!jsonData1 || jsonData1 === "[]") {
                    // console.log("Received empty initial chunk for PPE data");
                    return;
                  }
              
                  // Log raw data for debugging
                  // console.log("Raw PPE data received:", jsonData1);
              
                  // Parse the data
                  const ppeData = JSON.parse(jsonData1);
              
                  // Skip if no data
                  if (!ppeData || !Array.isArray(ppeData)) {
                    // console.log("Invalid PPE data format received");
                    return;
                  }
              
                  // Format the PPE data
                  const formattedPPE = ppeData.map((item, index) => ({
                    id: index,
                    activity: item.activity,
                    ppeDescription: item.ppeDescription,
                    australianStandards: item.australianStandards
                  }));
              
                  // console.log("Formatted PPE data:", formattedPPE);
                  setQ4(formattedPPE);
                  setPercentage(4);
              
                } catch (error) {
                  if (error instanceof SyntaxError) {
                    // console.log("Received partial PPE data, waiting for complete response");
                  } else {
                    console.error("Error parsing Q4 data:", error);
                    // console.log("Raw data received:", jsonData1);
                  }
                }
                break;
                case 5:
                  try {
                    // Skip empty initial chunk
                    if (!jsonData1 || jsonData1 === "[]") {
                      // console.log("Received empty initial chunk for permits data");
                      return;
                    }
                
                    // Log raw data for debugging
                    // console.log("Raw permits data received:", jsonData1);
                
                    // Parse the JSON data
                    const permitsData = JSON.parse(jsonData1);
                
                    // Skip if no data
                    if (!permitsData || !Array.isArray(permitsData)) {
                      // console.log("Invalid permits data format received");
                      return;
                    }
                
                    // Map the data to the expected format
                    const formattedPermits = permitsData.map((permit, index) => ({
                      id: index,
                      number: permit["Number"] || "",
                      permitName: permit["Permit Name"] || "",  // Fixed property name
                      howToAcquire: Array.isArray(permit["How to Acquire Permit"])
                        ? permit["How to Acquire Permit"].join("\n")
                        : permit["How to Acquire Permit"] || "",
                      whyRequired: permit["Why the Permit is Required"] || "",
                    }));
                
                    // console.log("Formatted permits data:", formattedPermits);
                    setQ5(formattedPermits);
                    setPercentage(5);
                
                  } catch (error) {
                    if (error instanceof SyntaxError) {
                      // console.log("Received partial permits data, waiting for complete response");
                    } else {
                      console.error("Error parsing Q5 data:", error);
                      // console.log("Raw data received:", jsonData1);
                    }
                  }
                  break;
            case 6:
              try {
                // Parse the JSON string to get the array of legislative items
                const legislativeItems = JSON.parse(jsonData1);

                // Map the items to the expected format
                const formattedItems = legislativeItems.map((item) => ({
                  Number: item.Number,
                  "List the Legislative Reference and ACOPs": Array.isArray(
                    item["List the Legislative Reference and ACOPs"]
                  )
                    ? item["List the Legislative Reference and ACOPs"].join(
                        "\n"
                      )
                    : item["List the Legislative Reference and ACOPs"],
                  "Why the legislation and ACOPs apply": Array.isArray(
                    item["Why the legislation and ACOPs apply"]
                  )
                    ? item["Why the legislation and ACOPs apply"].join("\n")
                    : item["Why the legislation and ACOPs apply"],
                }));

                setQ6(formattedItems);
                setPercentage(6);
              } catch (error) {
                console.error("Error parsing Q6 data:", error);
              }
              break;
              case 7:
                try {
                  // console.log("Raw Q7 data received:", event.data);

                  // Parse the JSON data
                  const cleanData = event.data.replace(/^data: /, '').trim();
                  if (!cleanData || cleanData === "[]") {
                    // console.log("Empty Q7 data received");
                    return;
                  }
                  // console.log("cleanData", cleanData);

                  let parsedData;
                  try {
                    // If the data is already a string, parse it. If it's an object, use it directly
                    parsedData = typeof event.data === 'string' ? JSON.parse(cleanData) : event.data;
                    // console.log("Parsed Q7 data:", parsedData);

                    // Ensure parsedData is an array
                    if (!Array.isArray(parsedData)) {
                      // If it's a string, try parsing it again
                      try {
                        parsedData = JSON.parse(parsedData);
                      } catch (e) {
                        console.error("Could not parse data into array:", e);
                        return;
                      }
                    }

                    // Now we're sure parsedData is an array
                    setQ7(parsedData);
                    setPercentage(7);

                  } catch (parseError) {
                    console.error("JSON parse error:", parseError);
                    // console.log("Data that caused error:", cleanData);
                  }

                } catch (error) {
                  // console.error("Error processing Q7 data:", error);
                  // console.log("Raw data that caused error:", event.data);
                }
                break;
            default:
              break;
          }
        } catch (error) {
          console.error("Error parsing event data as JSON:", error);
        }
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
        eventSource.close();
        setLoading(false);
      };

      // After processing all messages, save the document with the selected color
      // await handleSaveDocument(color);  // Pass the selected color to handleSaveDocument

      handleClose();
    } catch (error) {
      console.error("Error during API call:", error);
      setLoading(false);
    }
  };

  const handleGoToDashboard = async () => {
    if (typeof handleSaveDocument === "function") {
      await handleSaveDocument(color);
      navigate("/user-dashboard/all-documents");
    } else {
      navigate("/user-dashboard/all-documents");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ borderRadius: "3px" }}
    >
      <DialogTitle sx={{ bgcolor: color, borderRadius: "3px 3px 0 0" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            sx={{
              padding: 0,
              color: "black",
              mr: 1, // Add margin-right to space out from the text
            }}
          >
            <Bot />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: "bold",
            }}
          >
            SWMS Autocomplete App
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <IconButton
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setShowSketchPicker(!showSketchPicker)}
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: color,
                border: "2px solid #DDDDDD",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  "& .MuiSvgIcon-root": {
                    opacity: 1, // Show icon on hover
                  },
                },
              }}
            >
              <PaletteIcon
                sx={{
                  fontSize: "18px",
                  color: "black",
                  // opacity: 0,
                }}
              />
            </Box>
          </IconButton>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          borderLeft: `4px solid ${color}`,
          borderRight: `4px solid ${color}`,
          mb: 0,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          bgcolor: "#E9EAEC",
        }}
      >
        <Box sx={{ mb: 2, position: "absolute", zIndex: 9, right: 10 }}>
          {showSketchPicker && (
            <SketchPicker
              color={color}
              onChange={handleColorChange}
              disableAlpha={true}
            />
          )}
        </Box>
        <Box
          sx={{
            border: `2px solid ${color}`,
            padding: 2,
            borderRadius: "3px",
            mb: 0,
            mt: 2,
            position: "relative",
          }}
        >
          <form>
            <InputLabel
              htmlFor={`task`}
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Task Details
            </InputLabel>
            <TextareaAutosize
              id={`task`}
              name="task"
              placeholder="Providing a comprehensive and detailed scope of work here ensures a more accurate and tailored Safe Work Method Statement (SWMS) document, reflecting the specific risks and controls relevant to the task you want to cover in this SWMS document."
              value={formValues.task}
              onChange={handleFormChange}
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
              className="small-placeholder"
            />
            <InputLabel
              htmlFor={`location`}
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Location and Site Details
            </InputLabel>
            <TextareaAutosize
              id={`location`}
              name="location"
              placeholder="Provide specific and detailed information about the site location and any relevant environmental factors or restrictions. Include descriptions of methods, plants, and work details unique to the site. Any special considerations, like using specific machinery or safety protocols, should be clearly outlined. The more precise the details, the more tailored the safety measures can be."
              value={formValues.location}
              onChange={handleFormChange}
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
              className="small-placeholder"
            />
            <InputLabel
              htmlFor={`includeTask`}
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Must Include Tasks (Optional)
            </InputLabel>
            <TextareaAutosize
              id={`includeTask`}
              name="includeTask"
              placeholder="All construction sites are unique, so it’s important to list the essential tasks that must be included in your SWMS to address your project's specific needs. Be specific about activities you want listed or compliance procedures that are critical for this job. For example, tasks might include special operational procedures, specific protocols, or safety measures for hazardous activities."
              value={formValues.includeTask}
              onChange={handleFormChange}
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
              className="small-placeholder"
            />
            <InputLabel
              htmlFor={`excludeTask`}
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Must Exclude Tasks (Optional)
            </InputLabel>
            <TextareaAutosize
              id={`excludeTask`}
              name="excludeTask"
              placeholder="Sometimes in your SWMS, you may want to exclude certain tasks that will be covered in a different SWMS document. For example, you might want to exclude scaffolding work or working with power tools to keep this document focused. Be clear about which tasks you intend to leave out so the SWMS remains relevant to the specific activities it covers."
              value={formValues.excludeTask}
              onChange={handleFormChange}
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
              className="small-placeholder"
            />
            <InputLabel
              htmlFor={`numberOfActivities`}
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Number of Activities
            </InputLabel>
            <TextField
              type="number"
              name="numberOfActivities"
              value={formValues.numberOfActivities}
              onChange={handleFormChange}
              variant="outlined"
              size="small"
              inputProps={{ min: min, max: max }}
              sx={{
                width: "100%",
                bgcolor: "white",
                borderRadius: "5px",
                fontFamily: "Roboto, sans-serif",
              }}
            />
          </form>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          borderLeft: `4px solid ${color}`,
          borderRight: `4px solid ${color}`,
          bgcolor: "#E9EAEC",
          borderBottom: `4px solid ${color}`,
          borderRadius: "0 0 3px 3px",
          paddingRight: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={handleGoToDashboard}
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "#f0f0f0",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              minWidth: "unset",
              width: "45px",
              height: "45px",
              padding: 0,
              "&:hover": {
                boxShadow: 10,
              },
              "&:active": {
                boxShadow: 2,
                bgcolor: "#d0d0d0",
              },
            }}
          >
            <ArrowBigLeft size={48} bgcolor="#f0f0f0" fill="#f0f0f0" />
          </Button>
          <Typography
            sx={{
              marginLeft: "10px",
              fontFamily: "Roboto, sans-serif",
              fontSize: "15px",
              fontWeight: "medium",
              color: "black",
            }}
          >
            Back to Dashboard
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
              marginRight: 1,
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              bgcolor: loading ? lighten(color, 0.2) : color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: loading ? 1 : 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
          >
            {loading ? (
              <>
                <CircularProgress
                  size={24}
                  sx={{ color: darken(color, 0.4) }}
                />
                <Typography
                  sx={{ marginLeft: "5px", color: darken(color, 0.4) }}
                >
                  Submit
                </Typography>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ColorPickerAndApiModal;
