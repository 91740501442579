"use client"

import React, { useState, useEffect, useCallback, useRef } from "react"
import { motion, AnimatePresence, useAnimation } from "framer-motion"
import Particles from "react-tsparticles"
import { loadSlim } from "tsparticles-slim"
import { Menu, X, Cpu, Cog, Bot, Plus, BrainCircuit } from "lucide-react"
import { Card, CardContent, CardFooter } from "../components/components_ui_card"
import BannerMiddle from '../assets/BannerMiddle.png'
import BannerTop from '../assets/BannerTop.png'

const gradientStyle = `
  .gradient-bg {
    background: linear-gradient(to bottom, #000000, #1a365d, #3182ce);
  }
`

const CodeAnimation = () => {
  const scrollbarStyles = `
     .code-window::-webkit-scrollbar {
       width: 6px;
       height: 6px;
     }
     .code-window::-webkit-scrollbar-track {
       background: transparent;
     }
     .code-window::-webkit-scrollbar-thumb {
       background-color: rgba(255, 255, 255, 0.3);
       border-radius: 3px;
     }
     .text-construction-yellow {
       color: #FFD700;
     }
   `
  const [lines, setLines] = useState([])
  const [command, setCommand] = useState("")
  const [commandHistory, setCommandHistory] = useState([])
  const controls = useAnimation()
  const codeWindowRef = useRef(null)

  const addLine = useCallback(() => {
    const newLine = {
      id: Date.now(),
      content: generateRandomCode(),
      opacity: 1
    }
    setLines(prevLines => [...prevLines, newLine])
  }, [])

  useEffect(() => {
    for (let i = 0; i < 20; i++) {
      addLine()
    }
    const interval = setInterval(addLine, 800)
    return () => clearInterval(interval)
  }, [addLine])

  useEffect(() => {
    if (codeWindowRef.current) {
      codeWindowRef.current.scrollTop = codeWindowRef.current.scrollHeight
    }
  }, [lines])

  const handleCommandSubmit = e => {
    e.preventDefault()
    if (command.trim()) {
      setCommandHistory(prev => [...prev, `$ ${command}`])
      setCommand("")
      setTimeout(() => {
        setCommandHistory(prev => [...prev, generateCommandOutput(command)])
      }, 500)
    }
  }

  return (
    <div
      className="w-full bg-blue-200 bg-opacity-40 rounded-lg p-4 overflow-hidden font-mono text-sm flex flex-col border border-white border-opacity-20"
      style={{ height: "calc(3 * (4rem + 2 * 1rem + 1.5rem) + 9rem)" }}
    >
      <style>{scrollbarStyles}</style>
      <div
        ref={codeWindowRef}
        className="flex-grow overflow-y-auto pr-2 mb-4 code-window"
        style={{ maxHeight: "calc(100% - 3rem)" }}
      >
        {lines.map((line, index) => (
          <motion.div
            key={line.id}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: line.opacity, x: 0 }}
            transition={{ duration: 0.5 }}
            className="text-construction-yellow mb-1"
          >
            {line.content}
          </motion.div>
        ))}
      </div>
      <div className="border-t border-blue-300 pt-2">
        {commandHistory.map((cmd, index) => (
          <div key={index} className="text-construction-yellow mb-1">
            {cmd}
          </div>
        ))}
        <form onSubmit={handleCommandSubmit} className="flex">
          <span className="text-construction-yellow mr-2">$</span>
          <input
            type="text"
            value={command}
            onChange={e => setCommand(e.target.value)}
            className="flex-grow bg-transparent text-construction-yellow placeholder-construction-yellow placeholder-opacity-70 focus:outline-none"
            placeholder="Enter command..."
          />
        </form>
      </div>
    </div>
  )
}

const generateRandomCode = () => {
  const codeSnippets = [
    "import { useState, useEffect } from 'react'",
    "const [data, setData] = useState([])",
    "useEffect(() => { fetchData() }, [])",
    "const fetchData = async () => {",
    "  const response = await fetch('/api/data')",
    "  const result = await response.json()",
    "  setData(result)",
    "}",
    "const processData = (input) => input.map(item => item * 2)",
    "class AIModel {",
    "  constructor(params) {",
    "    this.params = params",
    "  }",
    "  train(data) {",
    "    // Training logic here",
    "  }",
    "  predict(input) {",
    "    // Prediction logic here",
    "  }",
    "}",
    "const model = new AIModel({ learningRate: 0.01 })",
    "model.train(trainingData)",
    "const prediction = model.predict(newData)",
    "const optimizeWorkflow = (steps) => {",
    "  return steps.filter(step => step.isNecessary)",
    "}",
    "const automatedTask = cron.schedule('0 * * * *', () => {",
    "  runAutomation()",
    "})",
    "function* dataGenerator() {",
    "  yield* processLargeDataset()",
    "}",
    "const result = Array.from(dataGenerator())",
    "const memoizedCalculation = memoize(complexCalculation)",
    "export const AIComponent = React.memo(({ data }) => {",
    "  // Render optimized AI component",
    "})"
  ]

  return codeSnippets[Math.floor(Math.random() * codeSnippets.length)]
}

const generateCommandOutput = command => {
  const outputs = [
    "Process completed successfully.",
    "Error: Command not found. Please check your syntax.",
    "Running AI model... Prediction accuracy: 95%",
    "Data processing complete. 10000 records affected.",
    "Automated workflow optimized. Efficiency increased by 30%.",
    "New package installed successfully.",
    "Deployment initiated. ETA: 5 minutes.",
    "API endpoint created. Test with 'curl http://api.example.com/v1/data'",
    "Database migration completed. 3 tables updated.",
    "Generating report... Done. Check '/reports/analysis_2024.pdf'"
  ]
  return outputs[Math.floor(Math.random() * outputs.length)]
}

const AnimatedText = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      {children}
    </motion.div>
  )
}

const Spinner = () => {
  return (
    <div className="flex items-center text-white">
      <div className="relative w-8 h-8 mr-3">
        <div className="absolute inset-0 border-2 border-white border-opacity-20 rounded-full"></div>
        <div className="absolute inset-0 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
        <div className="absolute inset-1 border-2 border-white border-opacity-20 rounded-full"></div>
        <div
          className="absolute inset-1 border-t-2 border-l-2 border-white rounded-full animate-spin"
          style={{ animationDirection: "reverse" }}
        ></div>
      </div>
      <span className="text-sm font-mono">Compiling...</span>
    </div>
  )
}

const AnimatedBackground = () => {
  return (
    <motion.div
      className="fixed inset-0"
      style={{ zIndex: -999 }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: [0.3, 0.5, 0.3],
        scale: [1, 1.2, 1],
      }}
      transition={{
        duration: 15,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut"
      }}
    >
      <div className="absolute inset-0">
        <div className="w-full h-full bg-gradient-to-br from-blue-900/50 via-purple-900/50 to-blue-900/50" />
      </div>
    </motion.div>
  )
}

export default function HomePage() {
  const [loaded, setLoaded] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [expandedCard, setExpandedCard] = useState(null)

  useEffect(() => {
    setLoaded(true)
  }, [])

  const particlesInit = useCallback(async engine => {
    await loadSlim(engine)
  }, [])

  const cardData = [
    {
      title: "Advanced and Agile AI Applications",
      icon: Cpu,
      description:
        "Empower your construction business with adaptable AI solutions that enhance efficiency and accuracy.",
      extendedInfo: (
        <>
          <h4 className="font-bold mb-2">What They Are:</h4>
          <p className="mb-4">
            Advanced and agile AI applications are sophisticated software
            systems that utilise artificial intelligence techniques like machine
            learning and natural language processing. They are designed to
            perform complex tasks, learn from data, and adapt quickly to
            changing requirements.
          </p>

          <h4 className="font-bold mb-2">
            How They Help in the Construction Industry:
          </h4>
          <ul className="list-disc pl-5 mb-4">
            <li>
              Automated Document Generation: AI can rapidly create essential
              documents such as SWMS, risk assessments, and environmental plans
              by analysing project data and regulatory requirements.
            </li>
            <li>
              Natural Language Processing (NLP): Enables AI to understand and
              generate human language, assisting in drafting and reviewing
              documents for compliance.
            </li>
            <li>
              Predictive Analytics: Analyses historical data to forecast
              potential risks and suggest mitigation strategies, leading to more
              accurate planning.
            </li>
            <li>
              Continuous Learning: Agile AI applications improve over time by
              learning from each project, adapting to new regulations and
              industry practices automatically.
            </li>
          </ul>

          <h4 className="font-bold mb-2">Benefits:</h4>
          <ul className="list-disc pl-5">
            <li>
              Speed: Generates complex documents in minutes instead of hours or
              days.
            </li>
            <li>
              Accuracy: Reduces human error by consistently applying standards
              and regulations.
            </li>
            <li>
              Compliance: Ensures all documentation meets the latest legal
              requirements.
            </li>
            <li>
              Customisation: Produces tailored documents specific to each
              project's needs.
            </li>
          </ul>
        </>
      )
    },
    {
      title: "Automations",
      icon: Cog,
      description:
        "Streamline your documentation processes through automation, reducing manual effort and errors.",
      extendedInfo: (
        <>
          <h4 className="font-bold mb-2">What They Are:</h4>
          <p className="mb-4">
            Automations involve using technology to perform repetitive and
            routine tasks with minimal human intervention. In construction
            documentation, this means automating processes like data entry,
            approvals, and updates.
          </p>

          <h4 className="font-bold mb-2">
            How They Help in the Construction Industry:
          </h4>
          <ul className="list-disc pl-5 mb-4">
            <li>
              Data Entry Automation: Automatically populates documents with data
              from other systems, minimising manual input and errors.
            </li>
            <li>
              Workflow Automation: Streamlines approval processes for permits,
              change orders, and reports with automated notifications and
              reminders.
            </li>
            <li>
              Template Management: Utilises standardised templates that update
              automatically with the latest regulatory information.
            </li>
            <li>
              Integration with Existing Systems: Connects various software
              platforms for seamless data flow, enhancing efficiency.
            </li>
          </ul>

          <h4 className="font-bold mb-2">Benefits:</h4>
          <ul className="list-disc pl-5">
            <li>Efficiency: Speeds up document creation and management.</li>
            <li>
              Consistency: Ensures all documents adhere to company and
              regulatory standards.
            </li>
            <li>
              Cost Savings: Reduces labour costs associated with manual tasks.
            </li>
            <li>
              Focus on Core Tasks: Allows staff to concentrate on strategic
              activities rather than routine paperwork.
            </li>
          </ul>
        </>
      )
    },
    {
      title: "Intelligent Agents",
      icon: Bot,
      description:
        "Leverage intelligent agents to provide real-time guidance and support in documentation tasks.",
      extendedInfo: (
        <>
          <h4 className="font-bold mb-2">What They Are:</h4>
          <p className="mb-4">
            Intelligent agents are AI-powered software entities that can
            perceive their environment, make decisions, and perform tasks on
            behalf of users. They offer assistance by understanding user needs
            and acting accordingly.
          </p>

          <h4 className="font-bold mb-2">
            How They Help in the Construction Industry:
          </h4>
          <ul className="list-disc pl-5 mb-4">
            <li>
              Virtual Assistants: Provide real-time help in filling out forms
              and documents, ensuring accuracy and compliance.
            </li>
            <li>
              Chatbots: Available around the clock to answer questions and guide
              users through complex documentation processes.
            </li>
            <li>
              Proactive Notifications: Monitor deadlines and send alerts for
              upcoming renewals, inspections, or submissions.
            </li>
            <li>
              Personalised Support: Tailor assistance based on the user's role
              or specific project requirements.
            </li>
          </ul>

          <h4 className="font-bold mb-2">Benefits:</h4>
          <ul className="list-disc pl-5">
            <li>
              Accessibility: Offer immediate assistance without waiting for
              human support.
            </li>
            <li>
              Accuracy: Deliver reliable information based on up-to-date data
              and regulations.
            </li>
            <li>
              Productivity: Reduce time spent searching for information,
              increasing overall efficiency.
            </li>
            <li>
              Training Aid: Help new employees understand documentation
              processes and compliance needs.
            </li>
          </ul>
        </>
      )
    }
  ]

  return (
    <>
      <AnimatedBackground />
      <div className="flex flex-col min-h-screen text-white gradient-bg relative">
        <style>{gradientStyle}</style>
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            background: {
              color: {
                value: "transparent"
              }
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push"
                },
                onHover: {
                  enable: true,
                  mode: "repulse"
                },
                resize: true
              },
              modes: {
                push: {
                  quantity: 4
                },
                repulse: {
                  distance: 100,
                  duration: 0.4
                }
              }
            },
            particles: {
              color: {
                value: "#ffffff"
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce"
                },
                random: false,
                speed: 1,
                straight: false
              },
              number: {
                density: {
                  enable: true,
                  area: 800
                },
                value: 80
              },
              opacity: {
                value: 0.5
              },
              shape: {
                type: "circle"
              },
              size: {
                value: { min: 1, max: 5 }
              }
            },
            detectRetina: true
          }}
          className="absolute inset-0"
        />
        <div className="container mx-auto z-10 px-4">
          <div className="w-full">
            <img
              src={BannerTop}
              alt="Banner Top"
              className="w-full object-cover"
              style={{ maxHeight: '200px' }}
            />
          </div>
        </div>
        <div className="container mx-auto px-4 py-16 lg:py-12">
          <nav className="flex justify-between items-center mb-16">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="flex items-center"
            >
              <div className="w-64 h-16 relative -ml-5">
                <img
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/icrommLogoWhiteLarge-dxN47NGSf06gm9tB445BDIqFdKTr6A.png"
                  alt="ICROMM Logo"
                  className="absolute inset-0 w-full h-full object-contain"
                />
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="relative"
            >
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="text-blue-400 hover:text-blue-300 transition-colors duration-300 font-extrabold text-xl flex items-center"
              >
                <span className="mr-2">MENU</span>
                {menuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
              <AnimatePresence>
                {menuOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute right-0 mt-2 space-y-2 text-right"
                  >
                    <a
                      href="#"
                      className="block text-blue-400 hover:text-blue-300 transition-colors duration-300 font-bold"
                    >
                      CONTACT
                    </a>
                    <a
                      href="/login"
                      className="block text-blue-400 hover:text-blue-300 transition-colors duration-300 font-bold"
                    >
                      LOGIN
                    </a>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </nav>
          <div className="flex flex-col items-center z-10 justify-between">
            <div className="w-full mb-10">
              <AnimatedText>
                <h2 className="text-5xl font-bold mb-6 leading-tight">
                  Simplifying Construction Complexities through{" "}
                  <span className="text-blue-400">
                    INTELLIGENT AUTOMATION SOLUTIONS
                  </span>
                </h2>
                <AnimatedText>
                  <p className="text-xl">
                    Empowering businesses with advanced and agile AI
                    applications, automations, and intelligent agents.
                  </p>
                </AnimatedText>
              </AnimatedText>
            </div>

            <div className="w-full z-10 mb-10">
              <img
                src={BannerMiddle}
                alt="Banner Top"
                className="w-full rounded-2xl object-cover"
                style={{ maxHeight: '200px' }}
                />
            </div>

            <div className="flex flex-col lg:flex-row items-start z-10 justify-between w-full mb-16">
              <div className="w-full lg:w-1/2 mb-10 lg:mb-0 lg:pr-4 space-y-4">
                {cardData.map((card, index) => (
                  <motion.div
                    key={index}
                    layout
                    transition={{ type: "spring", stiffness: 300 }}
                    className={expandedCard === index ? "col-span-full" : ""}
                  >
                    <Card
                      className={`${
                        expandedCard === index
                          ? "bg-yellow-400 text-black"
                          : "bg-blue-200 bg-opacity-40 text-white"
                      } transition-colors duration-300 border border-white border-opacity-20 cursor-pointer`}
                      onClick={() =>
                        setExpandedCard(expandedCard === index ? null : index)
                      }
                    >
                      <CardContent className="p-4" style={{ minHeight: "120px" }}>
                        <div className="flex items-center mb-2">
                          {React.createElement(card.icon, {
                            className: `w-5 h-5 ${
                              expandedCard === index
                                ? "text-black"
                                : "text-yellow-400"
                            } mr-2`
                          })}
                          <h3 className="text-lg font-bold">{card.title}</h3>
                        </div>
                        <p className="text-sm">{card.description}</p>
                        {expandedCard === index && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            className="mt-4"
                          >
                            {card.extendedInfo}
                          </motion.div>
                        )}
                      </CardContent>
                      <CardFooter className="p-4 pt-0">
                        <div
                          className={`w-5 h-5 ${
                            expandedCard === index
                              ? "text-black"
                              : "text-blue-400"
                          } cursor-pointer hover:text-blue-300 transition-colors duration-300`}
                        >
                          <Plus />
                        </div>
                      </CardFooter>
                    </Card>
                  </motion.div>
                ))}
              </div>
              <div className="w-full lg:w-1/2 h-auto">
                <CodeAnimation />
              </div>
            </div>
            {/* New Yellow Section with BrainCircuit icon */}
            <div className="w-full bg-yellow-400 z-10 text-black rounded-lg p-8 mb-16">
              <div className="flex items-center mb-6">
                <BrainCircuit className="w-10 h-10 mr-4" />
                <h3 className="text-3xl font-bold">
                  Advancing Construction Documentation with Advanced
                  Intelligence and Human Expertise
                </h3>
              </div>
              <div className="space-y-4">
                <p>
                  At ICROMM, we are focused on the construction industry by
                  leveraging advanced applications, automation, and intelligent
                  agents. Our mission is to simplify the complexities of
                  construction documentation, turning time consuming tasks into
                  efficient, accurate, and effortless processes. By integrating
                  innovative technology with deep industry expertise, we provide
                  solutions that streamline workflows, enhance safety, ensure
                  compliance, and elevate overall project performance. Our
                  comprehensive approach covers a wide range of essential
                  documents, from risk assessments and hazard analyses to
                  environmental management plans and quality assurance records.
                </p>
                <p>
                  We are excited to announce that our{" "}
                  <strong>AutoDoc SWMS</strong> AI is currently in beta testing.
                  This groundbreaking tool automates the creation of Safe Work
                  Method Statements (SWMS) and numerous other critical documents
                  required in the construction industry. With a visually
                  stunning and user-friendly interface, AutoDoc enables you to
                  generate complex documents in minutes rather than hours or
                  days. Its intelligent algorithms ensure exceptional accuracy,
                  consistently applying the latest regulatory standards and
                  minimizing the risk of human error. Importantly, our system is
                  designed to work with a human in the loop, allowing seasoned
                  professionals to provide the final polish and expert insights
                  that only experienced individuals in their fields can offer.
                </p>
                <p>
                  Imagine having intricate reports and compliance documents
                  crafted swiftly and precisely, yet still retaining the
                  valuable expertise of your team. Our solutions offer not just
                  speed and accuracy but also the ease of use that comes with
                  intuitive design. Editing and updating the outputted data is
                  straightforward, allowing for effortless customisation and
                  refinement to meet your specific project needs. Whether it's
                  automating incident reports, scheduling inspections, or
                  managing training records, ICROMM is redefining what's
                  possible in the construction industry. We invite you to join
                  us on this exciting journey and experience firsthand how our
                  technology can empower your business to reach new levels of
                  efficiency and confidence.
                </p>
              </div>
            </div>
          </div>
        </div>
        <footer className="relative z-10 bg-blue-400 py-8 px-4 text-white text-sm mt-auto">
          <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-8">
            <p className="text-center md:text-left flex-grow">
            © 2024 ICROMM Pty Ltd. ICROMM acknowledges the Traditional Owners of
            the lands on which we work and pays respect to their Elders, past and
            present.
            </p>
            <Spinner />
          </div>
        </footer>
      </div>
    </>
  )
}
