import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  TextField,
  Card,
  Box,
  InputLabel,
  TextareaAutosize,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/roboto";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormContext } from "../../context/FormContext";

const RequiredPermits = ({ color, q5 }) => {
  const { permitsData, handlePermitsChange, documentColor } = useContext(FormContext);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (q5 && q5.length > 0) {
      const updatedPermits = q5.map((item, index) => ({
        id: index,
        number: item.Number,
        permitName: item["Permit Name"],
        whyRequired: Array.isArray(item["Why the Permit is Required"])
          ? item["Why the Permit is Required"].map((proc) => `- ${proc.trim()}`).join("\n")
          : item["Why the Permit is Required"],
        howToAcquire: Array.isArray(item["How to Acquire Permit"])
          ? item["How to Acquire Permit"].map((proc) => `- ${proc.trim()}`).join("\n")
          : item["How to Acquire Permit"],
      }));

      handlePermitsChange(updatedPermits);
    } else if (!permitsData || permitsData.length === 0) {
      handlePermitsChange([{
        id: 0,
        number: "",
        permitName: "",
        howToAcquire: "",
        whyRequired: "",
      }]);
    }
  }, [q5, handlePermitsChange, permitsData]);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAddPermit = () => {
    const newPermit = {
      id: permitsData.length,
      number: "",
      permitName: "",
      howToAcquire: "",
      whyRequired: "",
    };
    handlePermitsChange([...permitsData, newPermit]);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handlePermitChange = (id, e) => {
    const { name, value } = e.target;
    const updatedPermits = permitsData.map((permit) =>
      permit.id === id 
        ? {
            ...permit,
            [name]: name === 'number' || name === 'permitName' || name === 'whyRequired'
              ? value 
              : value.split('\n').filter(item => item.trim() !== '') 
          }
        : permit
    );
    handlePermitsChange(updatedPermits);
  };

  const handleDeletePermit = (id) => {
    if (permitsData.length > 1) {
      const updatedPermits = permitsData.filter((permit) => permit.id !== id);
      handlePermitsChange(updatedPermits);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-anchor" : undefined;

  return (
    <Card
      sx={{
        boxShadow: 3,
        border: `4px solid ${documentColor}`,
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: documentColor,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "50px",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton
              aria-describedby={id}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
              sx={{ padding: 0, color: "black" }}
            >
              <WarningAmberIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                if (expanded) {
                  stopPropagation(e);
                  handleIconClick(e);
                }
              }}
            >
              Required Permits
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent
            sx={{
              padding: 0,
              marginBottom: 0,
              "&:last-child": {
                paddingBottom: 0,
              },
            }}
          >
            {permitsData && permitsData.map((permit) => (
              <Box
                key={permit.id}
                sx={{
                  border: `2px solid ${documentColor}`,
                  padding: 2,
                  borderRadius: "3px",
                  mb: 2,
                  position: "relative",
                  "&:last-child": {
                    mb: 0,
                  },
                }}
              >
                {showDeleteIcons && permitsData.length > 1 && (
                  <IconButton
                    onClick={() => handleDeletePermit(permit.id)}
                    sx={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      padding: "0",
                      color: "red",
                    }}
                  >
                    <CancelIcon sx={{ fontSize: "15px" }} />
                  </IconButton>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <InputLabel
                      htmlFor={`number-${permit.id}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      Number
                    </InputLabel>
                    <TextField
                      fullWidth
                      id={`number-${permit.id}`}
                      name="number"
                      value={permit.number}
                      onChange={(e) => handlePermitChange(permit.id, e)}
                      variant="outlined"
                      size="small"
                      sx={{
                        bgcolor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <InputLabel
                      htmlFor={`permitName-${permit.id}`}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#424242",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      Permit Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      id={`permitName-${permit.id}`}
                      name="permitName"
                      value={permit.permitName}
                      onChange={(e) => handlePermitChange(permit.id, e)}
                      variant="outlined"
                      size="small"
                      sx={{
                        bgcolor: "white",
                        borderRadius: "5px",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`howToAcquire-${permit.id}`}
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Roboto, sans-serif",
                        fontWeight: "bold",
                        color: "#424242",
                      }}
                    >
                      How to Acquire Permit
                    </InputLabel>
                    <TextareaAutosize
                      id={`howToAcquire-${permit.id}`}
                      name="howToAcquire"
                      value={permit.howToAcquire}
                      onChange={(e) => handlePermitChange(permit.id, e)}
                      minRows={4}
                      style={{
                        minWidth: "98.7%",
                        fontFamily: "Roboto, sans-serif",
                        maxWidth: "98.7%",
                        padding: "8px",
                        marginTop: "8px",
                        borderRadius: "5px",
                        borderColor: "grey",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`whyRequired-${permit.id}`}
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Roboto, sans-serif",
                        fontWeight: "bold",
                        color: "#424242",
                      }}
                    >
                      Why the Permit is Required
                    </InputLabel>
                    <TextareaAutosize
                      id={`whyRequired-${permit.id}`}
                      name="whyRequired"
                      value={permit.whyRequired}
                      onChange={(e) => handlePermitChange(permit.id, e)}
                      minRows={4}
                      style={{
                        minWidth: "98.7%",
                        fontFamily: "Roboto, sans-serif",
                        maxWidth: "98.7%",
                        padding: "8px",
                        marginTop: "8px",
                        borderRadius: "5px",
                        borderColor: "grey",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </CardContent>
        </AccordionDetails>
      </Accordion>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcons}
                onChange={(e) => setShowDeleteIcons(e.target.checked)}
                sx={{
                  color: documentColor,
                  "&.Mui-checked": {
                    color: documentColor,
                  },
                }}
              />
            }
            label="Delete Permit"
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
            startIcon={<AddIcon />}
            onClick={handleAddPermit}
          >
            Add Permit
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default RequiredPermits;
