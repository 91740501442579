import React from 'react';
import { Box, Typography } from '@mui/material';
import '@fontsource/roboto';


const CopyrightContainer = ({ contractorName = '[Your Company Name]' }) => {
    const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <Box
      sx={{
        backgroundColor: 'lightgrey',
        textAlign: 'center',
        padding: '10px',
        fontSize: '9px',
        mt: 2,
        
      }}
    >
      <Typography component="p" sx = {{fontSize: '9px', fontFamily: 'Roboto, sans-serif',color: 'rgb(103, 103, 103)'}}>
        &copy; {currentYear}. All rights reserved. This Safety Work Method Statement (SWMS) document and its contents are proprietary to{' '}
        <span className="footerCompany">{contractorName}</span> and are intended solely for the use of the individual or entity to
        whom they are addressed. This document contains confidential and/or legally privileged information. Any unauthorised use,
        disclosure, reproduction, modification, distribution, or taking of any action in reliance upon this document, except as
        expressly permitted by <span className="footerCompany">{contractorName}</span>, is strictly prohibited and may be unlawful.{' '}
        <span className="footerCompany">{contractorName}</span> is not responsible for any modifications made to this document by
        unauthorised third parties. The information contained herein is provided 'as is' without any express or implied warranty of
        any kind. In no event shall <span className="footerCompany">{contractorName}</span> be liable for any damages whatsoever,
        including, without limitation, direct, indirect, incidental, consequential, or punitive damages, arising out of the use of or
        inability to use the information contained in this document.
      </Typography>
    </Box>
  );
};

export default CopyrightContainer;
