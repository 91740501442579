import React, { useContext, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    CardContent,
    Grid,
    TextField,
    Card, Box, InputLabel, IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Clipboard } from 'lucide-react';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import '@fontsource/roboto';
import { FormContext } from '../context/FormContext';


const ProjectDetails = ({ color }) => {

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const { ProjectDetailsData, handleProjectDetailsChange } = useContext(FormContext)

    return (
        <Card sx={{ boxShadow: 3, border: `4px solid ${color}`, backgroundColor: 'white', opacity: 0.9 }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: color,
                        display: 'flex',
                        alignItems: 'center',
                        height: '60px',  // Set your preferred minHeight
                        '&.Mui-expanded': {
                            height: '50px',  // Apply the same height when expanded
                        }

                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Clipboard /> {/* Margin right to space icon from text */}
                        <Typography variant="h6" sx={{ ml: 1, fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>
                            Project Details
                        </Typography>
                    </Box>
                    {/* <IconButton
                        onClick={(e) => {
                            stopPropagation(e);
                            handlePrev();
                        }}
                        sx={{
                            bgcolor: color,
                            color: 'black',
                            borderRadius: '50%',
                            border: '2px solid #DDDDDD',
                            boxShadow: `
                                inset 0px 3.26px 3.26px 0px #FFFFFF26, 
                                inset 0px 0px 48.91px 0px #FFFFFF0D, 
                                9px 10px 7.1px 0px #00000066, 
                                -0.5px -0.5px 6.9px 0px #FFFFFF40
                            `,
                            '&:active': {
                                background: 'linear-gradient(150deg, #6096B4 0%, #000 100%)',
                            },
                            ml: { xs: 0.5, sm: 1 },  // Adjust margin for smaller screens
                        }}
                    >
                        <NorthIcon />
                    </IconButton>
                    <IconButton
                        onClick={(e) => {
                            stopPropagation(e);
                            handleNext();
                        }}
                        sx={{
                            bgcolor: color,
                            color: 'black',
                            borderRadius: '50%',
                            border: '2px solid #DDDDDD',
                            boxShadow: `
                                inset 0px 3.26px 3.26px 0px #FFFFFF26, 
                                inset 0px 0px 48.91px 0px #FFFFFF0D, 
                                9px 10px 7.1px 0px #00000066, 
                                -0.5px -0.5px 6.9px 0px #FFFFFF40
                            `,
                            '&:active': {
                                background: 'linear-gradient(150deg, #6096B4 0%, #000 100%)',
                            },
                            ml: 1,  // Adjust margin for smaller screens
                            mr: 5
                        }}
                    >
                        <SouthIcon />
                    </IconButton> */}
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: '#E9EAEC', padding: 2 }}>
                    <CardContent sx={{
                        padding: 0,
                        marginBottom: 0,
                        '&:last-child': {
                            paddingBottom: 0,  // Set padding-bottom of the last child to 0
                        }
                    }}>
                        <Box sx={{ mb: '20px', border: `2px solid ${color}`, padding: 2, borderRadius: '3px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        SWMS Title
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="swmsTitle"
                                        value={ProjectDetailsData.swmsTitle}
                                        onChange={handleProjectDetailsChange}
                                        // onBlur={() => handleBlur('swmsTitle')}  // Track when the user finishes editing
                                        // onFocus={() => handleFocus('swmsTitle')}
                                        // disabled={loading} // Disable when loading
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}

                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        SWMS No
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="swmsNo"
                                        value={ProjectDetailsData.swmsNo}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}

                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Work Location
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="workLocation"
                                        value={ProjectDetailsData.workLocation}
                                        onChange={handleProjectDetailsChange}
                                        // onBlur={() => handleBlur('workLocation')}  // Track when the user finishes editing
                                        // onFocus={() => handleFocus('workLocation')}
                                        // disabled={loading} // Disable when loading
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}

                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Rev No
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="revNo"
                                        value={ProjectDetailsData.revNo}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}

                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ border: `2px solid ${color}`, padding: 2, borderRadius: '3px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Project Name
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="projectName"
                                        value={ProjectDetailsData.projectName}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Date
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="date"
                                        type="date"
                                        value={ProjectDetailsData.date}
                                        onChange={handleProjectDetailsChange}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Contractor Name
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="contractorName"
                                        value={ProjectDetailsData.contractorName}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}

                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        ABN
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="abn"
                                        value={ProjectDetailsData.abn}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="jobDescription" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Address
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="address"
                                        value={ProjectDetailsData.address}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default ProjectDetails;
