import React, { useEffect, useState } from "react";

const ApprovedRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApprovedRequests = async () => {
      try {
        // Updated URL to include /api/ in the path
        const response = await fetch("https://www.icromm.com/api/users/approved");

        if (!response.ok) {
          throw new Error("Failed to fetch approved requests");
        }
        const data = await response.json();
        setRequests(data.approved_users || []); 
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApprovedRequests();
  }, []);

  const toggleEnabled = async (id) => {
    try {
      const request = requests.find((request) => request._id === id);
      const newStatus = !request.is_blocked;

      const response = await fetch(`https://www.icromm.com/api/users/toggle-block/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ is_blocked: newStatus }),
      });

      if (!response.ok) {
        throw new Error("Failed to update block status");
      }

      setRequests((prevRequests) =>
        prevRequests.map((request) =>
          request._id === id ? { ...request, is_blocked: newStatus } : request
        )
      );
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="h-full overflow-auto p-4">
      <h1 className="mb-6 text-3xl font-semibold text-center">All Approved Requests</h1>
      {!requests || requests.length === 0 ? (
        <div className="text-center text-gray-600 py-8">
          No approved requests available
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full text-left border-collapse border border-gray-300 bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">First Name</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Last Name</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Email</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Phone</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Timestamp</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Action</th>
              </tr>
            </thead>
            <tbody>
            {requests
                .filter((request) => !request.is_superadmin) // Exclude super admins
                .map((request) => (
                <tr key={request._id} className="hover:bg-gray-50">
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.first_name}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.last_name}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.email}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.phone}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">
                    {new Date(request.created_at * 1000).toLocaleString()}
                  </td>
                  <td className="py-3 px-6 border border-gray-200">
                    <div className="flex items-center justify-center">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only"
                          checked={request.is_blocked || false}
                          onChange={() => toggleEnabled(request._id)}
                        />
                        <div
                          className={`w-16 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ${
                            request.is_blocked ? "bg-red-500" : ""
                          }`}
                        >
                          <div
                            className={`w-6 h-6 bg-white rounded-full shadow-md transform duration-300 ease-in-out ${
                              request.is_blocked ? "translate-x-8" : ""
                            }`}
                          ></div>
                        </div>
                        <span className="ml-2 text-sm text-gray-700">
                          {request.is_blocked ? "Unblock" : "Block"}
                        </span>
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ApprovedRequests;
