import React, { useEffect, useState } from "react";

const DeclineRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeclineRequests = async () => {
      try {
        const response = await fetch("https://www.icromm.com/api/users/rejected");
        if (!response.ok) {
          throw new Error("Failed to fetch declined requests");
        }
        const data = await response.json();
        setRequests(data.rejected_users);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDeclineRequests();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="h-full overflow-auto p-4">
      <h1 className="mb-6 text-3xl font-semibold text-center">All Declined Requests</h1>
      {!requests || requests.length === 0 ? (
        <div className="text-center text-gray-600 py-8">
          No declined requests available
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full text-left border-collapse border border-gray-300 bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">First Name</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Last Name</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Email</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Phone</th>
                <th className="py-3 px-6 text-sm font-medium border border-gray-200">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((request) => (
                <tr key={request._id} className="hover:bg-gray-50">
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.first_name}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.last_name}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.email}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">{request.phone}</td>
                  <td className="py-3 px-6 border border-gray-200 whitespace-nowrap">
                    {new Date(request.created_at * 1000).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DeclineRequests;
