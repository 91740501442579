import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardContent,
  Grid,
  TextField,
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { FormContext } from '../context/FormContext';
import DeleteIcon from "@mui/icons-material/Delete";
import { ClipboardPenLine } from "lucide-react";

const FormReviewer = ({ color }) => {
  const { formReviewerData, handleFormReviewerChange } = useContext(FormContext);
  const [expanded, setExpanded] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!formReviewerData || formReviewerData.rows_page1.length === 0) {
      handleFormReviewerChange({
        rows_page1: [
          { reviewed_by: "", company: "", print_name: "", signature: "", date: "" },
          { reviewed_by: "", company: "", print_name: "", signature: "", date: "" },
          { reviewed_by: "", company: "", print_name: "", signature: "", date: "" },
        ]
      });
    }
  }, [formReviewerData, handleFormReviewerChange]);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...formReviewerData.rows_page1];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    handleFormReviewerChange({ ...formReviewerData, rows_page1: updatedRows });
  };

  const handleAddRow = () => {
    const newRow = { reviewed_by: "", company: "", print_name: "", signature: "", date: "" };
    handleFormReviewerChange({
      ...formReviewerData,
      rows_page1: [...formReviewerData.rows_page1, newRow]
    });
  };

  const handleDeleteRow = (index) => {
    const updatedRows = formReviewerData.rows_page1.filter((_, i) => i !== index);
    handleFormReviewerChange({ ...formReviewerData, rows_page1: updatedRows });
  };

  return (
    <Card sx={{ boxShadow: 3, border: `4px solid ${color}`, backgroundColor: "white", opacity: 0.9 }}>
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            height: "60px",
            "&.Mui-expanded": {
              height: "60px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              width: "100%",
            }}
          >
            <IconButton
              onClick={(e) => {
                if (expanded) {
                  e.stopPropagation();
                  handleIconClick(e);
                }
              }}
              sx={{
                padding: 0,
                color: "black",
                mr: 1,
              }}
            >
              <ClipboardPenLine />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "bold",
                }}
              >
                Form Reviewer
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#E9EAEC", padding: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ overflowX: "auto" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="form reviewer table">
                    <TableHead>
                      <TableRow sx={{ alignItems: "center" }}>
                        <TableCell sx={{ bgcolor: color, border: "1px solid black", fontWeight: "bold", padding: 1, textAlign: "center" }}>
                          Position
                        </TableCell>
                        <TableCell sx={{ bgcolor: color, border: "1px solid black", fontWeight: "bold", padding: 1, textAlign: "center" }}>
                          Company
                        </TableCell>
                        <TableCell sx={{ bgcolor: color, border: "1px solid black", fontWeight: "bold", padding: 1, textAlign: "center" }}>
                          Print name
                        </TableCell>
                        <TableCell sx={{ bgcolor: color, border: "1px solid black", fontWeight: "bold", padding: 1, textAlign: "center" }}>
                          Signature
                        </TableCell>
                        <TableCell sx={{ bgcolor: color, border: "1px solid black", fontWeight: "bold", padding: 1, textAlign: "center" }}>
                          Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formReviewerData.rows_page1.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ border: "1px solid black", padding: 1 }}>
                            <TextField
                              value={row.reviewed_by}
                              size="small"
                              onChange={(e) => handleRowChange(index, 'reviewed_by', e.target.value)}
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Position"
                            />
                          </TableCell>
                          <TableCell sx={{ border: "1px solid black", padding: 1 }}>
                            <TextField
                              value={row.company}
                              size="small"
                              onChange={(e) => handleRowChange(index, 'company', e.target.value)}
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Company"
                            />
                          </TableCell>
                          <TableCell sx={{ border: "1px solid black", padding: 1 }}>
                            <TextField
                              value={row.print_name}
                              size="small"
                              onChange={(e) => handleRowChange(index, 'print_name', e.target.value)}
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Print name"
                            />
                          </TableCell>
                          <TableCell sx={{ border: "1px solid black", padding: 1 }}>
                            <TextField
                              value={row.signature}
                              size="small"
                              onChange={(e) => handleRowChange(index, 'signature', e.target.value)}
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Signature"
                            />
                          </TableCell>
                          <TableCell sx={{ border: "1px solid black", padding: 1 }}>
                            <TextField
                              value={row.date}
                              size="small"
                              name="date"
                              type="date"
                              onChange={(e) => handleRowChange(index, 'date', e.target.value)}
                              fullWidth
                              sx={{
                                bgcolor: "white",
                                fontFamily: "Roboto, sans-serif",
                                borderRadius: "5px",
                              }}
                              placeholder="Date"
                            />
                          </TableCell>
                          {showDeleteIcon && (
                            <TableCell sx={{ padding: 0, width: "30px" }}>
                              <IconButton
                                onClick={() => handleDeleteRow(index)}
                                style={{ color: "red", fontSize: "0.8rem" }}
                              >
                                <CancelIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleteIcon}
                onChange={() => setShowDeleteIcon(!showDeleteIcon)}
                sx={{
                  color: color,
                  "&.Mui-checked": {
                    color: color,
                  },
                  "& .MuiCheckbox-root": {
                    color: color,
                    "&.Mui-checked": {
                      color: color,
                    },
                  },
                }}
              />
            }
            label="Delete Rows"
          />
          <Button
            onClick={handleAddRow}
            startIcon={<AddIcon />}
            variant="contained"
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
          >
            Add Row
          </Button>
        </Box>
      </Popover>
    </Card>
  );
};

export default FormReviewer;
