import React, { useContext } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    CardContent,
    Grid,
    TextField,
    Card, Box, InputLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Clipboard } from 'lucide-react';
import '@fontsource/roboto';
import { FormContext } from '../../context/FormContext';

const ProjectDetails = () => {
    const { ProjectDetailsData, handleProjectDetailsChange, documentColor } = useContext(FormContext);

    return (
        <Card sx={{ boxShadow: 3, border: `4px solid ${documentColor}`, backgroundColor: 'white', opacity: 0.9 }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: documentColor,
                        display: 'flex',
                        alignItems: 'center',
                        height: '60px',
                        '&.Mui-expanded': {
                            height: '50px',
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Clipboard />
                        <Typography variant="h6" sx={{ ml: 1, fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>
                            Project Details
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: '#E9EAEC', padding: 2 }}>
                    <CardContent sx={{
                        padding: 0,
                        marginBottom: 0,
                        '&:last-child': {
                            paddingBottom: 0,
                        }
                    }}>
                        <Box sx={{ mb: '20px', border: `2px solid ${documentColor}`, padding: 2, borderRadius: '3px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="swmsTitle" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        SWMS Title
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="swmsTitle"
                                        value={ProjectDetailsData.swmsTitle}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="swmsNo" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        SWMS No
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="swmsNo"
                                        value={ProjectDetailsData.swmsNo}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="workLocation" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Work Location
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="workLocation"
                                        value={ProjectDetailsData.workLocation}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="revNo" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Rev No
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="revNo"
                                        value={ProjectDetailsData.revNo}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ border: `2px solid ${documentColor}`, padding: 2, borderRadius: '3px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="projectName" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Project Name
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="projectName"
                                        value={ProjectDetailsData.projectName}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="date" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Date
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="date"
                                        type="date"
                                        value={ProjectDetailsData.date}
                                        onChange={handleProjectDetailsChange}
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <InputLabel htmlFor="contractorName" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Contractor Name
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="contractorName"
                                        value={ProjectDetailsData.contractorName}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel htmlFor="abn" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        ABN
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="abn"
                                        value={ProjectDetailsData.abn}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="address" sx={{ fontSize: '12px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
                                        Address
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="address"
                                        value={ProjectDetailsData.address}
                                        onChange={handleProjectDetailsChange}
                                        variant="outlined"
                                        size="small"
                                        sx={{ bgcolor: 'white', fontFamily: 'Roboto, sans-serif', borderRadius: '5px' }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default ProjectDetails;
